<template>
  <div>
    <b-container>
      <b-table :items="stats" :fields="fields" show-empty> 
      </b-table>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "playerresults",
  props: ['stats', 'config'],
  data() {
    return {
      noEntry: "noEntry",
      fields: [
        { key: "tourn_start", label: "Date" },
        { key: "tourn_name", label: "Name" },
        { key: "final_pos", label: "Pos" },
        { key: "r1_score", label: "Rounds", class: "RoundsRemove" },
        { key: "t_score", label: "Score" },
        { key: "winnings", label: "Points" }
      ]
    };
  },
};
</script>

<style scoped>
.noEntry {
  display: none;
}
::v-deep table>thead>tr>th{
  padding: 8px;
  background-color: #204232;
  color: #fff;
  font-weight: 100;
  border-top: 0px solid #dee2e6!important;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0.75rem;
}

::v-deep table>tbody>tr>td{
  text-align: left;
  padding: 8px;
  /* border-top: 1px solid #fff; */
  font-size: 13px;
  color: #6C6C6C;
}
::v-deep tr{
  /* background-color: rgba(0,0,0,.05); */
}

::v-deep th:first-child{
  text-align: left;
}
::v-deep td:first-child{
  text-align: left;
  color: #F2AF14;
  font-size: 13px;
  font-weight: 600;
}

::v-deep td:last-child{
  color: #3C3C3C;
  font-weight: 600;
  font-size: 13px;
}

@media only screen and (max-width: 768px) {
  ::v-deep th.RoundsRemove {
    display: none;
  }
  ::v-deep td.RoundsRemove {
    display: none;
  }
}
</style>
