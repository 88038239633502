var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',[_c('table',[_c('thead',{staticStyle:{"background-color":"#214232!important"}},[_c('tr',{staticStyle:{"background-color":"#214232!important"}},[_c('th',[_vm._v(" Pos ")]),_c('th',[_vm._v(" Name ")]),_c('th',[_vm._v(" Nat ")]),_c('th',{staticClass:"TextCen",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.data.value_1_head)+" ")]),_c('th',{staticClass:"mobNo TextCen"},[_vm._v(" "+_vm._s(_vm.data.oom_header)+" ")]),_c('th',{staticClass:"mobNo"})])]),_c('tbody',[_vm._l((_vm.data.oom.oom_entry.slice(0,11)),function(stats,index){return _c('tr',{key:index},[_c('td',{staticClass:"posColour"},[_vm._v(" "+_vm._s(stats.pos)+" ")]),_c('td',[_c('a',{staticClass:"OOMstatsColour",attrs:{"href":'/playerprofile/' + stats.playerno}},[_vm._v(" "+_vm._s(stats.name)+" "),(stats.lb_sponsored === 'Y')?_c('span',[_c('img',{staticClass:"event-lb-sponsor-logo",attrs:{"src":_vm.data.event_lb_sponsor_logo}})]):_vm._e()])]),_c('td',[_c('span',[_c('img',{class:_vm.flag,attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL +
                  stats.nationality))}})])]),_c('td',{staticClass:"TextCen"},[_vm._v(" "+_vm._s(stats.oom_value)+" ")]),(
            _vm.stat === 'ooms-oom-FT' ||
              _vm.stat === 'ooms-oom-FZ' ||
              _vm.stat === 'ooms-oom-RK'
          )?_c('td',{staticClass:"mobNo"},[_vm._v(" "+_vm._s(stats.tournaments_played)+" ")]):_c('td',{staticClass:"mobNo TextCen"},[_vm._v(" "+_vm._s(stats.played)+" ")]),_c('td',{staticClass:"mobNo"},[_c('a',{staticClass:"moreInfo",attrs:{"href":'/playerprofile' + '/' + stats.playerno}},[_vm._v(" More Info "),_c('span',{staticClass:"rightArrow"},[_c('font-awesome-icon',{staticClass:"icon rightArrow",attrs:{"icon":['fa', 'chevron-right']}})],1)])])])}),(_vm.isHidden)?_vm._l((_vm.data.oom.oom_entry.slice(11,1000)),function(stats,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(stats.pos)+" ")]),_c('td',[_c('a',{staticClass:"OOMstatsColour",attrs:{"href":'/playerprofile/' + stats.playerno}},[_vm._v(" "+_vm._s(stats.name)+" "),(stats.lb_sponsored === 'Y')?_c('span',[_c('img',{staticClass:"event-lb-sponsor-logo",attrs:{"src":_vm.data.event_lb_sponsor_logo}})]):_vm._e()])]),_c('td',[_c('span',[_c('img',{class:_vm.flag,attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL +
                    stats.nationality))}})])]),_c('td',{staticClass:"TextCen"},[_vm._v(" "+_vm._s(stats.oom_value)+" ")]),(
              _vm.stat === 'ooms-oom-FT' ||
                _vm.stat === 'ooms-oom-FZ' ||
                _vm.stat === 'ooms-oom-RK'
            )?_c('td',{staticClass:"mobNo"},[_vm._v(" "+_vm._s(stats.tournaments_played)+" ")]):_c('td',{staticClass:"mobNo TextCen"},[_vm._v(" "+_vm._s(stats.played)+" ")]),_c('td',{staticClass:"mobNo"},[_c('a',{staticClass:"moreInfo",attrs:{"href":'/playerprofile' + '/' + stats.playerno}},[_vm._v(" More Info "),_c('span',{staticClass:"rightArrow"},[_c('font-awesome-icon',{staticClass:"icon rightArrow",attrs:{"icon":['fa', 'chevron-right']}})],1)])])])}):_vm._e()],2)])]),_c('div',{staticClass:"seeMore",on:{"click":function($event){_vm.isHidden = !_vm.isHidden}}},[(!_vm.isHidden)?[_c('div',{staticClass:"SeeMoreText"},[_vm._v(" See More ")])]:[_c('div',{staticClass:"SeeMoreText"},[_vm._v(" See Less ")])]],2),_c('div',{staticClass:"middleAd-above-vid"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }