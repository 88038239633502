var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._l((_vm.ticketData),function(ticket,index){return _c('div',{key:index,staticClass:"backgroundColour"},[_c('b-container',[(_vm.isMobile(true))?[_c('b-row',[_c('b-col',{staticClass:"mobileImage",attrs:{"lg":"4","sm":"12"}},[_c('b-img',{staticClass:"tournImage",attrs:{"src":ticket.acf.tournament_image.url}})],1),_c('b-col',{attrs:{"lg":"8","sm":"12"}},[_c('h1',{staticClass:"tournamentName"},[_vm._v(_vm._s(ticket.acf.tournament_name))]),_c('b-row',{staticClass:"middleRow mobileMiddleRow"},[_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('div',{staticClass:"ticketpriceBlock middleTicketPriceBlock"},[_vm._v(" From "),_c('span',{staticClass:"ticketPrice"},[_vm._v(_vm._s(ticket.acf.ticket_price))]),_vm._v(" *incl VAT ")])]),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('div',{staticClass:"tournamentLocation"},[_vm._v(_vm._s(ticket.acf.tournament_location))]),_c('div',{staticClass:"tournamentDate"},[_vm._v(_vm._s(ticket.acf.tournament_date))])])],1),_c('p',{staticClass:"tournamentInfo",domProps:{"innerHTML":_vm._s(ticket.acf.tournament_information)}}),_c('b-link',{staticClass:"ticketButtonPos",attrs:{"href":ticket.acf.ticket_button_link,"target":"_blank"}},[_c('b-button',{staticClass:"ticketBut",attrs:{"variant":"success"}},[_vm._v(_vm._s(ticket.acf.ticket_button_text))])],1)],1)],1)]:[_c('b-link',{staticClass:"ticketBtn",attrs:{"to":{
                        name: 'ticket-open',
                        query: {
                            id: _vm.id,
                            code: ticket.acf.tournament_code,
                        },
                    },"target":"_blank"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"8","sm":"12"}},[_c('h1',{staticClass:"tournamentName"},[_vm._v(_vm._s(ticket.acf.tournament_name))]),_c('b-row',{staticClass:"middleRow"},[_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('div',{staticClass:"ticketpriceBlock"},[_vm._v(" From "),_c('span',{staticClass:"ticketPrice"},[_vm._v(_vm._s(ticket.acf.ticket_price))]),_vm._v(" *incl VAT ")])]),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('div',{staticClass:"tournamentLocation"},[_vm._v(_vm._s(ticket.acf.tournament_location))]),_c('div',{staticClass:"tournamentDate"},[_vm._v(_vm._s(ticket.acf.tournament_date))])])],1),_c('p',{staticClass:"tournamentInfo",domProps:{"innerHTML":_vm._s(ticket.acf.tournament_information)}}),_c('b-link',{staticClass:"ticketButtonPos",attrs:{"href":ticket.acf.ticket_button_link,"target":"_blank"}},[_c('b-button',{staticClass:"ticketBut",attrs:{"variant":"success"}},[_vm._v(_vm._s(ticket.acf.ticket_button_text))])],1)],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-img',{staticClass:"tournImage",attrs:{"src":ticket.acf.tournament_image.url}})],1)],1)],1)]],2)],1)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"aboutUsImage",attrs:{"src":"https://updated.sunshinetour.com/wp-content/uploads/2022/08/SunshineBoys_header.png"}})])
}]

export { render, staticRenderFns }