<template>
    <div class="playerList">
        <b-tabs class="tabR" nav-wrapper-class="container tabPlayerTab" content-class="mt-3">
            <b-tab class="playertab" title="CAREER STATISTICS" active>
                <b-container>
                    <b-row>
                        <b-col class="colBestMoney">
                            <span class="TitleBestMoney">
                              <span class="BestMoneyPos">Career Prize Money</span>
                              <span class="rightBestMoney"></span>
                            </span>
                            <span class="playerBestMoney">
                              <template v-if="playerinfo.STATISTICS.STATISTIC[0].DESCRIPTION == 'Career Prize Money'">{{playerinfoBestMoney}}</template>
                              <template v-else>0</template>
                            </span>
                        </b-col>
                        <div class="vl"></div>
                        <b-col class="colCareerMoney">
                            <span class="TitleCareerMoney">
                                <span class="leftCareerMoney"></span>
                                <span class="BestCareerPos">Best Single Prize Money</span>
                            </span>
                            <span class="playerCareerMoney">
                              <template v-if="playerinfo.STATISTICS.STATISTIC[1].DESCRIPTION == 'Best Single Prize Money'">{{playerinfoCareerMoney}}</template>
                              <template v-else>0</template>
                            </span>
                        </b-col>
                    </b-row>
                    <div class="hrLine"></div>
                    <b-row class="MiddleSectionStats">
                        <template v-if="isMobile(true)">
                          <b-col cols="12" sm='6'>
                            <b-row v-for="(stat, index) in playerinfo.STATISTICS.STATISTIC" :key="index" v-show="stat.DESCRIPTION == 'Stroke Average' || stat.DESCRIPTION == 'Average against Par' || stat.DESCRIPTION == 'Average Birdies per Round'">
                              <b-col>
                                <span class="leftstat"></span>
                                <span class="statLeftBottom">{{stat.DESCRIPTION}}</span>
                              </b-col>
                              <b-col>
                                <span class="RightValueBottom">{{stat.VALUE}}</span>
                              </b-col>
                            </b-row>
                          </b-col>
                        </template>
                        <template v-else>
                          <b-col class="" cols="12" lg="6" sm="12">
                              <b-row class="rowColor" v-for="(stat, index) in playerinfo.STATISTICS.STATISTIC" :key="index" v-show="stat.DESCRIPTION == 'Stroke Average' || stat.DESCRIPTION == 'Average against Par' || stat.DESCRIPTION == 'Average Birdies per Round'">
                                  <b-col class="" cols="5" lg="5" sm="5">
                                      <span class="leftstat" :class="[`LeftMidColor--${index}`]"></span>
                                      <span class="statLeftBottom" v-if="isMobile(true)">
                                          <p class="sD">{{stat.DESCRIPTION}}</p>
                                      </span>
                                      <span class="statLeftBottom" v-else>
                                          {{stat.DESCRIPTION}}
                                      </span>
                                  </b-col>
                                  <b-col class="mobStat" cols="2" lg="2" sm="2">
                                      <span class="RightValueBottom noRightFloat" :class="[`LeftMidColorValue--${index}`]">
                                          <template v-if="stat.VALUE.includes('-')">
                                              {{stat.VALUE}}
                                          </template>
                                          <template v-else>
                                              <Progress
                                                  :transitionDuration="2000"
                                                  :radius="0"
                                                  :strokeWidth="10"
                                                  :value="stat.VALUE"
                                              >
                                              </Progress>
                                          </template>
                                      </span>
                                  </b-col>
                                  <b-col class="HideMobile" cols="5" lg="5" sm="2">
                                      <span class="chart">
                                          <progress-bar :class="[`barColor--${index}`]" bar-color="#23A769" :val="stat.VALUE"></progress-bar>
                                      </span>
                                  </b-col>
                              </b-row>
                          </b-col>
                        </template>
                        <template v-if="isMobile(true)">
                        </template>
                        <template v-else>
                          <div class="vl-two"></div>
                        </template>
                        <template v-if="isMobile(true)">
                          <b-col cols="12" sm='6'>
                            <b-row v-for="(stat, index) in playerinfo.STATISTICS.STATISTIC" :key="index" v-show="stat.DESCRIPTION == 'Playoff Wins' || stat.DESCRIPTION == 'Tournament Wins' || stat.DESCRIPTION == 'Tournaments Played' || stat.DESCRIPTION == 'Tournament Top Ten Finishes' || stat.DESCRIPTION == 'Most Consecutive Cuts Made'">
                              <b-col>
                                <span class="leftstat"></span>
                                <span class="statLeftBottom">{{stat.DESCRIPTION}}</span>
                              </b-col>
                              <b-col>
                                <span class="RightValueBottom">{{stat.VALUE}}</span>
                              </b-col>
                            </b-row>
                          </b-col>
                        </template>
                        <template v-else>
                          <b-col class="" >
                            <b-row v-for="(stat, index) in playerinfo.STATISTICS.STATISTIC" :key="index"  v-show="stat.DESCRIPTION == 'Playoff Wins' || stat.DESCRIPTION == 'Tournament Wins' || stat.DESCRIPTION == 'Tournaments Played' || stat.DESCRIPTION == 'Tournament Top Ten Finishes' || stat.DESCRIPTION == 'Most Consecutive Cuts Made'"
                            >
                              <b-col class="" cols="5" lg="5" sm="5">
                                  <span class="leftstat" :class="[`RightMidColor--${index}`]"></span>
                                  <span class="statLeftBottom" v-if="isMobile(true)">
                                      <p class="sD">{{stat.DESCRIPTION}}</p>
                                  </span>
                                  <span class="statLeftBottom" v-else>
                                      {{stat.DESCRIPTION}}
                                  </span>
                              </b-col>
                              <b-col class="mobStat" cols="2" lg="2" sm="2">
                              <span class="RightValueBottom noRightFloat" :class="[`RightMidColorValue--${index}`]">
                                  <template v-if="stat.VALUE.includes('-')">
                                      {{stat.VALUE}}
                                  </template>
                                  <template v-else>
                                      <Progress
                                          :transitionDuration="2000"
                                          :radius="0"
                                          :strokeWidth="10"
                                          :value="stat.VALUE"
                                      >
                                      </Progress>
                                  </template>
                              </span>
                              </b-col>
                              <b-col class="HideMobile" cols="5" lg="5" sm="5">
                              <span class="chart">
                                  <progress-bar :class="[`barColorRight--${index}`]" bar-color="#23A769" :val="stat.VALUE"></progress-bar>
                              </span>
                              </b-col>
                            </b-row>
                          </b-col>
                        </template>
                    </b-row>
                    <div class="hrLine"></div>
                    <b-row class="BottomRowStats">
                        <b-col cols="12" sm='6'>
                            <b-row>
                                <b-col>
                                    <span class="leftstat"></span>
                                    <span class="statLeftBottom">Low Round Score</span>
                                </b-col>
                                <b-col>
                                    <span class="RightValueBottom" v-for="(stat, index) in playerinfo.STATISTICS.STATISTIC" :key="index" v-show="stat.DESCRIPTION === 'Low Round Score'">{{stat.VALUE}}</span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <span class="leftstat colorTwo"></span>
                                    <span class="statLeftBottom">Total Number of Eagles</span>
                                </b-col>
                                <b-col>
                                    <span class="RightValueBottom backTwo" v-for="(stat, index) in playerinfo.STATISTICS.STATISTIC" :key="index" v-show="stat.DESCRIPTION === 'Total Number of Eagles'">{{stat.VALUE}}</span>
                                </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                  <span class="leftstat"></span>
                                  <span class="statLeftBottom">OWGR Ranking</span>
                              </b-col>
                              <b-col>
                                  <template v-if="playerinfo.MEMBER.OWGR_RANKING.length">
                                    <span class="RightValueBottom">{{playerinfo.MEMBER.OWGR_RANKING}}</span>
                                  </template>
                                  <template v-else>
                                    <span class="RightValueBottom">N/A</span>
                                  </template>
                              </b-col>
                            </b-row>
                        </b-col>
                        <b-col>
                            <b-row>
                                <b-col>
                                    <span class="leftstat colorThree"></span>
                                    <span class="statLeftBottom">Total Number of Birdies</span>
                                </b-col>
                                <b-col>
                                    <span class="RightValueBottom backThree" v-for="(stat, index) in playerinfo.STATISTICS.STATISTIC" :key="index" v-show="stat.DESCRIPTION === 'Total Number of Birdies' && stat.CODE === 'BET6' || stat.CODE === 'S115'">{{stat.VALUE}}</span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <span class="leftstat colorFour"></span>
                                    <span class="statLeftBottom">Total Holes in One</span>
                                </b-col>
                                <b-col>
                                    <span class="RightValueBottom backFour" v-for="(stat, index) in playerinfo.STATISTICS.STATISTIC" :key="index" v-show="stat.DESCRIPTION === 'Total Holes in One' && stat.CODE === 'BET5' || stat.CODE === 'S125'">{{stat.VALUE}}</span>
                                </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <span class="leftstat colorThree"></span>
                                <span class="statLeftBottom">Order Of Merit Position</span>
                              </b-col>
                              <b-col>
                                <template v-if="playerinfo.ORDERS_OF_MERIT != null">
                                  <span class="RightValueBottom backThree">{{playerinfo.ORDERS_OF_MERIT.OOM.SEASON[0].POSITION}}</span>
                                </template>
                                <template v-else>
                                  <span class="RightValueBottom backThree">N/A</span>
                                </template>
                              </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </b-tab>
            <template v-if="enhancedPlayerInfo.length > 0">
              <b-tab class="playertab" title="RESULTS"><div class="topHeadersTable"></div><div class='tCont'><div><PlayerResults :stats="enhancedPlayerInfo.tourns.tourn_details" :config="config"/></div></div></b-tab>
              <b-tab class="playertab" title="STATISTICS RANKING"><div class="topHeadersTable"></div><div class='tCont'><PlayerStats :stats="enhancedPlayerInfo.stats.tourn_details" :config="config"/></div></b-tab>
            </template>
              <b-tab class="playertab" title="ORDER OF MERIT"><div class="topHeadersTable"></div><div class='tCont'><PlayerOom :stats="playerinfo.ORDERS_OF_MERIT" :config="config"/></div></b-tab>
              <b-tab class="playertab" title="THIS SEASON RECORD"><div class="topHeadersTable"></div><div class='tCont'><PlayerThisSeason :stats="playerinfo.THIS_SEASON_RECORD.TOURNAMENT" :config="config"/></div></b-tab>
              <b-tab class="playertab" title="LAST SEASON HIGHLIGHTS"><div class="topHeadersTable"></div><div class='tCont'><PlayerLastSeason :stats="playerinfo.LAST_SEASON_HIGHLIGHTS.TOURNAMENT" :config="config"/></div></b-tab>
              <b-tab class="playertab" title="CAREER HIGHLIGHTS"><div class="topHeadersTable"></div><div class='tCont'><PlayerCareer :stats="playerinfo.CAREER_HIGHLIGHTS.TOURNAMENT" :config="config"/></div></b-tab>
        </b-tabs>
    </div>
</template> 

<script>
import Progress from "easy-circular-progress";
import PlayerResults from "@/components/playerResults.vue"
import PlayerOom from "@/components/playerOrderofMerit.vue"
import PlayerStats from "@/components/playerStats.vue"
import PlayerThisSeason from "@/components/playerThisSeasonRecord.vue"
import PlayerLastSeason from "@/components/playerLastSeasonRecord.vue"
import PlayerCareer from "@/components/playerCareerHighlights.vue"
import ProgressBar from 'vue-simple-progress'
export default {
  name: "Player Profile Stats",
  components: {
    ProgressBar,
    Progress,
    PlayerOom, 
    PlayerThisSeason, 
    PlayerLastSeason, 
    PlayerResults, 
    PlayerStats, 
    PlayerCareer,
  },
  props: [
    "refno",
    "season", 
    "config",
    "playerinfo",
    "enhancedPlayerInfo",
    "enhancedPlayerOOM",
    "isMobile",
    "playerinfoCareerMoney",
    "playerinfoBestMoney"
  ],
  data() {
    return {
    };
  },
  metaInfo() {
    return {
      title: this.enhancedPlayerInfo.full_name,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  }
};
</script>

<style scoped>
.lastStats {
  margin-top: 30px;
}
.playerList {
  padding-bottom: 50px;
}
.oneDigit {
  position: relative;
  left: -0px;
  top: 2px;
}
.twoDigit {
  position: relative;
  left: -4px;
  top: 2px;
}
.threeDigit {
  position: relative;
  left: -12px; 
  top: 2px;
}
.sD {
  white-space: nowrap;
  /* width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
.vl {
  border-left: 1px solid #EAEAEA;
  height: 215px;
}
.vl-two {
  border-left: 1px solid #EAEAEA;
  height: 340px;
  margin-top: -50px;
}
/* leftHandSide Stats */
::v-deep .rowColor:nth-child(odd) > div > span.leftstat {
  color: #F2AF14;
  background: #F2AF14;
}
::v-deep .rowColor:nth-child(odd) > .mobStat > span {
  color: #F2AF14;
}
::v-deep .rowColor:nth-child(even) > div > span.leftstat{
  color: #006737;
  background: #006737;
}
::v-deep .rowColor:nth-child(even) > .mobStat > span{
  color: #006737;
}
span.LeftMidColor--9 {
  background-color: #F2AF14;
}
span.LeftMidColor--12 {
  background-color: #006737;
}
span.LeftMidColorValue--9 {
  color: #F2AF14;
}
span.LeftMidColorValue--12 {
  color: #006737;
}
::v-deep .barColor--9 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #204232!important;
}
::v-deep .barColor--7 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #204232!important;
}
::v-deep .barColor--12 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #F2AF14!important;
}
/* End leftHandSide Stats */

/* RightHandSide Stats */
span.RightMidColor--2 {
  background-color: #006737;
}
span.RightMidColorValue--2 {
  color: #006737;
}
::v-deep .barColorRight--2 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #006737!important;
}
span.RightMidColor--3 {
  background-color: #F2AF14;
}
span.RightMidColorValue--3 {
  color: #F2AF14;
}
::v-deep .barColorRight--3 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #F2AF14!important;
}
span.RightMidColor--4 {
  background-color: #D0D0D0;
}
span.RightMidColorValue--4 {
  color: #D0D0D0;
}
::v-deep .barColorRight--4 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #D0D0D0!important;
}
span.RightMidColor--5 {
  background-color: #204232;
}
span.RightMidColorValue--5 {
  color: #204232;
}
::v-deep .barColorRight--5 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #204232!important;
}
span.RightMidColor--6 {
  background-color: #F2AF14;
}
span.RightMidColorValue--6 {
  color: #F2AF14;
}
::v-deep .barColorRight--6 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #F2AF14!important;
}
/* End RightHandSide Stats */

span.chart {
  margin-top: 12px;
  display: block;
}
.noRightFloat {
  float: none!important;
}
::v-deep .vue-simple-progress {
  height: 15px;
}
::v-deep .vue-simple-progress-bar {
  height: 15px!important;
}
/* ::v-deep span.dot {
  display: none;
}
::v-deep span.percent__dec {
  display: none;
} */
::v-deep span.percent_sign {
  display: none;
}
.MiddleSectionStats {
  margin-top: 50px;
  /* margin-bottom: 50px; */
}
.colorTwo {
  background-color: #006737!important;
}
.colorThree {
  background-color: #F2AF14!important;
}
.colorFour {
  background-color: #D0D0D0!important;
}
.backTwo {
  color: #006737!important;
}
.backThree {
  color: #F2AF14!important;
}
.backFour {
  color: #D0D0D0!important;
}
.BottomRowStats {
  margin-top: 40px;
  margin-bottom: 40px;
}
.RightValueBottom {
  color: #23A769;
  font-size: 24px;
  font-weight: bold;
  margin-top: 4px;
  display: block;
  float: right;
}
.statLeftBottom {
  color: #6C6C6C;
  font-size: 13px;
  margin-left: 20px;
}
.hrLine {
  color: #EAEAEA;
  border-bottom: 1px solid;
  /* margin-top: 25px; */
}
.BestCareerPos {
  margin-left: 15px
}
.BestMoneyPos {
  margin-right: 15px
}
.rightBestMoney {
  width: 4px;
  height: 25px;
  background: #F2AF14;
  display: inline-block;
  border-radius: 2px;
  position: relative;
  top: 9px;
}
.leftCareerMoney {
  width: 4px;
  height: 25px;
  background: #006737;
  display: inline-block;
  border-radius: 2px;
  position: relative;
  top: 9px;
}
.leftstat {
  width: 4px;
  height: 25px;
  background: #23A769;
  display: inline-block;
  border-radius: 2px;
  position: relative;
  top: 9px;
}
.TitleBestMoney {
  display: block;
  color: #707070;
  font-size: 13px;
  margin-top: 60px;
  margin-bottom: 20px;
}
.TitleCareerMoney {
  display: block;
  color: #707070;
  font-size: 13px;
  margin-top: 60px;
  margin-bottom: 20px;
}
.colBestMoney {
  text-align: right;
}
.colCareerMoney {
  text-align: left;
}
span.playerCareerMoney {
  color: #006737;
  font-size: 40px;
  font-weight: bold;
}
span.playerBestMoney {
  color: #F2AF14;
  font-size: 40px;
  font-weight: bold;
}
.one.col-lg-1.col-12 {
  height: 20px;
  width: 6px;
  background: #f2af15;
  position: relative;
  right: -23px;
  z-index: 9999;
  text-align: center;
  top: 120px;
  padding-top: 5px;
  padding-bottom: 40px;
  border-radius: 38px;
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  max-width: 4.9%;
}
.topHeadersTable {
  background-color: #204232;
  position: absolute;
  left: 0;
  right: 0;
  height: 44px;
}
li.nav-item.socialLinkClass > a {
  padding: 0px;
}
span.socailBlock {
  display: inline-flex;
}
h2.socialName {
  color: #6C6C6C;
  font-weight: 100;
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 1.8;
  padding-left: 5px;
  padding-right: 10px;
}
img.socialIcon {
  width: 25px;
  height: 25px;
}
.profileTopimage {
  padding-right: 0px;
}
.playerTopData {
  background-color: #fff;
  border-radius: 0px 10px 10px 0px;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  padding-bottom: 60px;
}
.outerContainer {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
}
::v-deep table {
  width: 100%;
  z-index: 999;
  position: relative;
}
span.mediaplayer-top {
  display: flex;
  margin-top: 15px;
}
h3.mediaPlayerTopSec {
  color: #F2AF14;
  margin-left: 15px;
  font-weight: 500;
  margin-top: 8px;
  font-size: 16px;
}
::v-deep .tabR > div > ul > li:hover {
  background-color: #fff;
}
::v-deep .tabR > div > ul > li > a:hover {
  color: rgb(33 66 50)!important;
}
::v-deep .nav-link .active{
  background-color: #fff!important;
  color: rgb(33 66 50)!important;
}
.cList {
  background-color: #fff;
  border-radius: 10px;
  margin: 5px;
  flex: 0 0 32.333333%;
  max-width: 33.333333%;
  box-shadow: 3px 3px #e4e1e1;
}
.cDes {
  color: #006837;
  margin-top: 15px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
}
.cValue {
  color: #f2af15;
  font-size: 15pt;
  font-weight: 600;
  margin-bottom: 10px !important;
}
.playType{
  width: 40px;
  margin-top: 30px;
  float: right;
}
.tCont{
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  padding-top: 0px;
}
.playerprofile {
  padding-bottom: 50px;
}
.playerData {
  padding: 50px 0;
  width: 900px;
}
.titleCont {
  position: relative;
  top: 49%;
  transform: translateY(-50%);
  text-align: center;
}
li {
  list-style-type: none;
}

.playerName {
  width: 100%;
  display: block;
  margin-top: 1em;
  padding-bottom: 2px;
  font-size: 21px;
  color: #6C6C6C;
  font-weight: bold;
 
  text-transform: capitalize;
}
.flagPlayerProfile {
  /* max-width: 25px; */
  max-width: 35px;
  height: 100%;
  margin-top: 4px;
  /* box-shadow: 0px 0px 3px #555; */
}
.icon {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.socialLinks {
  float: none;
  display: inline-flex;
}
.playerProfileData {
  color: #939393;
  margin-left: 15px;
  font-weight: 100;
  margin-top: 13px;
  font-size: 18px;
  line-height: 25px;
}
.playerProfileTitle {
  color: #565656;
  font-size: 10pt;
  text-transform: capitalize;
  margin: 0;
}
.playerProfileRow {
  /* padding: 20px; */
  font-size: 20pt;
}
.playerImg {
  /* width: 100%; */
  border-radius: 10px 0px 0px 10px;
}
.tabR {
  background-color: #006737!important;
}
::v-deep .nav-tabs{
  border: 0;
}
::v-deep .nav-tabs .nav-link {
  color: #fff !important;
  text-transform: capitalize;
  padding: 25px;
  font-size: 10px;
  padding-top: 35px;
  padding-bottom: 35px;
  display: block;
  border: 0;
  font-weight: bold;
}
::v-deep .nav-tabs .nav-link {
  margin-bottom: 0px;
}
::v-deep .nav-link.active {
  color: #fff !important;
  background-color: #204232;
  border-radius: 0;
  font-weight: bold;
}
::v-deep .tabR > div > ul > li > a:hover {
  color: #fff !important;
  background-color: #204232;
  border-radius: 0;
  font-weight: bold;
}
::v-deep .mt-3 {
  padding-top: 0px;
  /* background: url(https://updated.sunshinetour.com/wp-content/uploads/2021/04/SunshineTourBG_2-scaled-1.jpeg); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #fff;
}
::v-deep .mt-3, .my-3 {
  margin-top: 0rem!important;
}
@media only screen and (max-width: 991px) {
  .mobStat {
    flex: 50%;
    max-width: 50%;
  }
  .RightValueBottom {
    color: #23A769;
    font-size: 24px;
    font-weight: bold;
    margin-top: 4px;
    display: block;
    float: right!important;
  }
  .HideMobile {
    display: none;
  }
  .socialLinks {
    float: none;
    display: block;
  }
  ::v-deep .container.tabPlayerTab > ul > li {

  }
  ::v-deep .container.tabPlayerTab {
    max-width: 100%;
  }
  ::v-deep .nav-tabs .nav-link {
    color: #fff !important;
    text-transform: capitalize;
    padding: 0;
    font-size: 8pt!important;
    padding-top: 25px;
    padding-bottom: 25px;
    display: block;
    border: 0;
    font-weight: bold;
    text-align: center;
  }
  ::v-deep .nav-tabs {
    border: 0;
    overflow: scroll;
    width: 100%;
    /* flex-wrap: nowrap; */
    display: block;
  }
  .outerContainer {
    height: auto;
  }
  .one {
    display: none;
  }
  .playerTopData {
    border-radius: 0px 0px 10px 10px;
  }
  .playerImg {
    width: 100%;
    border-radius: 10px 10px 0px 0px
  }
  .profileTopimage {
    padding-left: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .tabR {
    background-color: #006737!important;
    margin-top: -1px;
  }
  ::v-deep .container.tabPlayerTab {
    max-width: 100%;
    /* height: 49px; */
  }
  hr.mobHRHide {
    display: none;
  }
  .playerTopData {
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
  }
  .statLeftBottom {
    color: #6C6C6C;
    font-size: 13px;
    margin-left: 12px;
    margin-top: -18px;
    display: block;
  }
  span.playerCareerMoney {
    color: #006737;
    font-size: 25px;
    font-weight: bold;
  }
  span.playerBestMoney {
    color: #F2AF14;
    font-size: 25px;
    font-weight: bold;
  }
  .playerData {
    padding: 50px 30px;
    text-align: center;
    width: 100%;
  }
  span.mediaplayer-top {
    display: flex;
    margin-top: 0px;
    text-align: left;
    padding-left: 0px;
    margin-bottom: 40px;
  }
  ::v-deep .playerList > div > div > ul {
    display: -webkit-inline-box;
    text-align: center;
    /* padding-top: 15px;
    padding-bottom: 15px; */
  }
  ::v-deep .nav-tabs .nav-link {
    color: #fff;
    text-transform: capitalize;
    padding: 15px;
    font-size: 10pt;
    padding-top: 15px;
    padding-bottom: 15px;
    display: block;
    border: 0;
  }
  ::v-deep .cList {
    background-color: #fff;
    border-radius: 10px;
    margin: 5px;
    flex: 100%;
    max-width: 100%;
    box-shadow: 3px 3px #e4e1e1;
  }
  .playTypeImage.col-lg-4 {
    position: absolute;
  }
}
</style>