<template>
    <div>
        <div>
            <img class="aboutUsImage" src="https://updated.sunshinetour.com/wp-content/uploads/2022/08/SunshineBoys_header.png">
        </div>
        <div v-for="(ticket, index ) in ticketData" :key="index" class="backgroundColour">
            <b-container>
                <template v-if="isMobile(true)">
                    <b-row>
                        <b-col lg="4" sm="12" class="mobileImage">
                            <b-img class="tournImage" :src="ticket.acf.tournament_image.url" ></b-img>
                        </b-col>
                        <b-col lg="8" sm="12">
                            <h1 class="tournamentName">{{ticket.acf.tournament_name}}</h1>
                            <b-row class="middleRow mobileMiddleRow">
                                <b-col lg="6" sm="6">
                                    <div class="ticketpriceBlock middleTicketPriceBlock">
                                        From <span class="ticketPrice">{{ticket.acf.ticket_price}}</span> *incl VAT
                                    </div>
                                </b-col>
                                <b-col lg="6" sm="6">
                                    <div class="tournamentLocation">{{ticket.acf.tournament_location}}</div>
                                    <div class="tournamentDate">{{ticket.acf.tournament_date}}</div>
                                </b-col>
                            </b-row>
                            <p class="tournamentInfo" v-html="ticket.acf.tournament_information"></p>
                            <b-link :href="ticket.acf.ticket_button_link" class="ticketButtonPos" target="_blank">
                                <b-button class="ticketBut" variant="success">{{ticket.acf.ticket_button_text}}</b-button>
                            </b-link>
                        </b-col>
                    </b-row>
                </template>
                <template v-else>
                    <b-link :to="{
                            name: 'ticket-open',
                            query: {
                                id: id,
                                code: ticket.acf.tournament_code,
                            },
                        }" target="_blank" class="ticketBtn">
                        <b-row>
                            <b-col lg="8" sm="12">
                                <h1 class="tournamentName">{{ticket.acf.tournament_name}}</h1>
                                <b-row class="middleRow">
                                    <b-col lg="6" sm="6">
                                        <div class="ticketpriceBlock">
                                            From <span class="ticketPrice">{{ticket.acf.ticket_price}}</span> *incl VAT
                                        </div>
                                    </b-col>
                                    <b-col lg="6" sm="6">
                                        <div class="tournamentLocation">{{ticket.acf.tournament_location}}</div>
                                        <div class="tournamentDate">{{ticket.acf.tournament_date}}</div>
                                    </b-col>
                                </b-row>
                                <p class="tournamentInfo" v-html="ticket.acf.tournament_information"></p>
                                <b-link 
                                    :href="ticket.acf.ticket_button_link" class="ticketButtonPos" target="_blank">
                                    <b-button class="ticketBut" variant="success">{{ticket.acf.ticket_button_text}}</b-button>
                                </b-link>
                            </b-col>
                            <b-col lg="4" sm="12">
                                <b-img class="tournImage" :src="ticket.acf.tournament_image.url" ></b-img>
                            </b-col>
                        </b-row>                    
                    </b-link>
                </template>
            </b-container>
        </div>
    </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
    name:"Tickets",
    props: ["config"],
    data(){
        return {
            wpInfoTitle: '',
            ticketData: [],
            data: [],
            id: this.id
        }
    },
    metaInfo() {
        return {
            title: 'Tickets',
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
    methods: {
        isMobile() {
            if( screen.width <= 990 ) {
                return true;
            }
            else {
                return false;
            }
        },
    },
    async mounted() {
        var one = "wp/v2/ticketspage" + "?randomadd=" + new Date().getTime()
        try {
            // First call, await for call to return
            let first_call = await apiCall.wpInfo(one);
            this.ticketData = first_call.data;
            this.loading = false;
            // Second call, await for call to return
            let second_call = await apiCall.tmParamsBase();
            this.tm_params = second_call.data;
            this.id = second_call.data.tm_params.season_code;
            console.log("this.id")
            console.log(this.id)
            this.loading = false;
            // Third call, await for call to return
            let third_call = await apiCall.tmParamsYear(this.id);
            this.data = third_call.data;
            this.loading = false;
        } catch (error) {
            console.log("error in await calls");
            console.log(error);
            throw error;
        }
        if (this.loggedin.status === null || this.loggedin === '') {
            this.notLogin()
        } else {
            console.log("error in await calls");
        }
    },
}
</script>

<style scoped>
a.ticketBtn {
    text-decoration: none;
}
.middleTicketPriceBlock {
    margin-bottom: 30px;
}
.mobileMiddleRow {
    text-align: center;
}
.mobileImage {
    margin-bottom: 30px;
}
.ticketButtonPos{
    /* position: absolute; */
    /* bottom: 0; */
    /* width: 38%; */
}
.middleRow {
    padding-bottom: 30px;    
}
.ticketpriceBlock {
    background: #F2AF14 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    text-align: center;
    font-size: 12px;
}
.ticketPrice {
    font-weight: bold;
    font-size: 25px;
}
.tournamentLocation {
    color: #717171;
    font-size: 15px;
}
.tournamentDate {
    color: #F2AF14;
    font-size: 15px;
    font-weight: bold;
}
.ticketBut {
    background-color: #006737;
    border-radius: 0px;
    border-color: #006737;
    padding: 10px 20px;
    width: 200px;
    font-size: 11px;
    font-weight: bold;
}
.ticketBut:hover {
    background-color: #204232;
    border-radius: 0px;
    border-color: #204232;
    padding: 10px 20px;
    width: 200px;
    font-size: 11px;
    font-weight: bold;
}
p.tournamentInfo {
    color: #939393;
    margin-bottom: 0;
    text-align: left;
    font-size: 9pt;
}
h1.tournamentName {
    color: #6C6C6C;
    font-weight: 700;
    font-size: 21px;
    padding-bottom: 30px;
}
.aboutUsImage {
    width: 100%;
}
img.tournImage {
    width: 100%;
    background: white;
    padding: 7px;
}
.backgroundColour:nth-child(odd) {
    background-color: #F2F2F2;
}
.backgroundColour {
    padding-top: 50px;
    padding-bottom: 50px;
}
@media only screen and (max-width: 990px) {
    .ticketButtonPos {
        position: relative;
        bottom: 0;
        width: 100%;
    }
    .ticketBut {
        width: 100%;
        background-color: #006737;
        border-radius: 0px;
        border-color: #006737;
        font-size: 20px;
    }
    .ticketBut:hover {
        width: 100%;
        background-color: #204232;
        border-radius: 0px;
        border-color: #204232;
    }
}
</style>