<template>
  <div id="players">
    <b-container>
      <div class="sections">
        <b-row>
          <b-col lg="4">
            <h4 class="title">
              Our Players 
            </h4>
          </b-col>
        </b-row>
      </div>
      <div class='tThree'>
        <TopThree :data='data' :config='config' :DEFIMAGESAMS3="DEFIMAGESAMS3"/>
      </div>
    </b-container>
    <PlayerList 
      :data='data' 
      :config='config' 
      :fields="fields" 
      :items="items" 
      :letters="letters" 
      flag='flag'
      :DEFIMAGESAMS3="DEFIMAGESAMS3"
      :filter="filter"
      :filterOn="filterOn"
    />
  </div>
</template>

<script>
import TopThree from '@/components/topThree.vue'
import PlayerList from '@/components/playersList.vue'
import apiCall from "@/services/api.js";
export default {
  props:['season', 'config'],
  components:{
    TopThree,
    PlayerList
  },
  data() {
    return {
      items: [],
      details: "details",
      cell: "cell",
      flag: "flag",
      fields: [
        { key: "name", label: "Name", class: "namePlayer"},
        { key: "ref_no", label: "Profile Image", class: "profileImage"},
        { key: "nat", label: "Nationality", class: "text-center" },
        { key: "represents", label: "Represents", class: "text-center represents" },
        { key: "turned_pro", label: "Turned Pro", class: "text-center" },
        { key: "actions", label: "", class: "moreInfo" },
      ],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      data:[],
      stat: "-ooms-oom-" + process.env.VUE_APP_OOM, 
      letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    };
  },
  metaInfo() {
    return {
      title: 'Players List',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg"; 
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.players();
      this.items = first_call.data.MEMBERS.PLAYER;
      this.DEFIMAGESAMS3 = first_call.data.GENERAL.DEFIMAGESAMS3;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.stat(this.season, this.stat);
      this.data = second_call.data.oom.oom_entry;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
  methods: {
    alphaContacts: function(l) {
      return this.items.filter(i => {
        return i.surname.toLowerCase().indexOf(l.toLowerCase()) === 0;
      });
    }
  }
};
</script>
<style scoped>
::v-deep .title {
  padding: 0px 0;
  color: #6C6C6C;
  font-weight: bold;
  font-size: 21px;
  text-transform: capitalize;
  margin-top: 50px;
}
#players {
  padding-top: 30px;
}
.tThree {
  padding: 30px 0;
}
</style>
