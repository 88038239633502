<template>
    <div>
        <b-container>
            <a :href="'/partners-and-sponsors'" class="backBTN">
                Back To All Partners & Sponsors
            </a>
            <!-- <a :href="'/the-papwa-swegolum-class'" class="backBTN">
                Back To The Papwa Swegolum Class
            </a> -->
            <b-row class="partnerBlock">
                <b-col cols="12" lg="4" md="4" sm="12">
                    <b-img v-if="partner[0].acf.partner_image === false" class="partnerImage" src="https://updated.sunshinetour.com/wp-content/uploads/2021/10/sunshine-tour-welcomes-back-limited-number-of-fans-at-tournaments-2.png"></b-img>
                    <b-img v-else class="partnerImage" :src="partner[0].acf.partner_image"></b-img>
                </b-col>
                <b-col cols="12" lg="8" md="8" sm="12">
                    <h2 class="partnerTitle" v-html="partner[0].title.rendered"></h2>
                    <p class="partnerDescript" v-html="partner[0].acf.partner_descript"></p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import apiCall from "@/services/api.js";

export default {
  name: "partner",
  props: ["slug", "dis"],
    data() {
        return {
        partner: [],
        partnerTitle: ''
        };
    },
    mounted() {
        apiCall
        .wpInfo("wp/v2/partnerspage?slug=" + this.slug)
        .then(({ data }) => {
            this.partner = data;
            this.partnerTitle = data[0].title.rendered;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
    },
    metaInfo() {
        return {
            title: this.partnerTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
};
</script>
<style scoped>
    .partnerTitle {
        color: #6C6C6C;
        font-weight: 300;
        font-size: 21px;
        text-transform: capitalize;
        /* padding-bottom: 25px;
        padding-top: 25px; */
       
    }
    p.partnerDescript {
        color: #6C6C6C;
        font-size: 13px;
    }
    .partnerBlock {
        margin-top: 50px;
    }
    .partnerImage {
        width: 100%;
        border: 1px solid #D0D0D0;
    }
    a.backBTN {
        display: block;
        /* margin: auto; */
        background: #006737;
        border: none;
        color: #fff!important;
        border-radius: 0;
        padding: 5px 20px;
        width: 230px;
        text-transform: capitalize;
        text-decoration: none !important;
        font-weight: 600;
        font-size: 11px;
        margin-top: 50px;
        height: 35px;
        line-height: 25px;
    }
    a.backBTN:hover {
        display: block;
        /* margin: auto; */
        background: rgb(33 66 50) !important;
        border: none;
        color: #fff!important;
        border-radius: 0;
        padding: 5px 20px;
        width: 230px;
        text-transform: capitalize;
        text-decoration: none !important;
        font-weight: 600;
        font-size: 11px;
        margin-top: 50px;
        height: 35px;
        line-height: 25px;
    }
    @media screen and (max-width: 767px){
        .partnerTitle {
            color: #6C6C6C;
            font-weight: 300;
            font-size: 21px;
            text-transform: capitalize;
           
            margin-top: 17px;
            margin-bottom: 15px;
        }
    }
</style>