<template>
  <b-container>
    <b-table
      v-if="Array.isArray(stats.OOM.SEASON)"
      :fields="fields"
      :items="stats.OOM.SEASON"
    >
    </b-table>
    <table v-else>
      <thead>
        <tr>
          <th class="title">
            Season Name
          </th>
          <th class="title">
            Pos
          </th>
          <th class="title">
            Points
          </th>
          <th class="title">
            Cash
          </th>
          <th class="title">
            Tournament
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="cell">
            {{ stats.OOM.SEASON.SEAS_NAME }}
          </td>
          <td class="cell">
            {{ stats.OOM.SEASON.POSITION }}
          </td>
          <td class="cell">
            {{ stats.OOM.SEASON.VALUE }}
          </td>
          <td class="cell">
            {{ stats.OOM.SEASON.CASH }}
          </td>
          <td class="cell">
            {{ stats.OOM.SEASON.TOURNS }}
          </td>
        </tr>
      </tbody>
    </table>
  </b-container>
</template>

<script>
export default {
  name: "playerOom",
  props: ["stats","config"],
  data() {
    return {
      fields: [
        { key: "SEAS_NAME", label: "Season Name" },
        { key: "POSITION", label: "Pos" },
        { key: "VALUE", label: "Points", class: "win" },
        { key: "TOURNS", label: "Tournament" }
      ]
    };
  }
};
</script>

<style scoped>
::v-deep td.win {
  color: #3C3C3C;
  /* font-weight: bold; */
  font-size: 13px;
}
::v-deep thead {
  color: white;
  background-color: #214232;
}
.cell {
  text-align: center;
}
.title {
  text-align: center;
  background-color: #214232;
  color: white;
  width: 5%;
}
::v-deep tbody > tr {
  /* background-color: rgba(0,0,0,.05); */
  /* border-top: 3px solid white; */
}
::v-deep td{
  text-align: left;
  padding: 6px;
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 22px;
  min-height: 38px;
  font-size: 13px;
  color: #6C6C6C;
}
::v-deep td:first-child{
  text-align: left;
  color: #f2af14;
  font-weight: 500;
  font-size: 13px;
}
::v-deep th:first-child{
  text-align: left;
}
::v-deep th{
  padding: 8px;
  /* padding: 13px; */
  text-align: left;
  color: #ffffff;
  font-size: 13px;
  font-weight: 100;
  border-top: 0px solid #dee2e6!important;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0.75rem;
  padding-left: 5px;
}
</style>
