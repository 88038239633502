<template>
  <div>
    <b-container>
      <b-table v-if="Array.isArray(stats)" :fields="fields" :items="stats">
        <template #cell(WINNINGS)="row">
          R {{ row.item.WINNINGS }}
        </template>
        <template #cell(POSITION)="row">
          {{ row.item.POSITION }}
        </template>
      </b-table>
      <table v-else>
        <thead>
          <tr>
            <th class="title">
              Date
            </th>
            <th class="title">
              Name
            </th>
            <th class="title">
              Position
            </th>
            <th class="title">
              Score
            </th>
            <th class="title">
              VSPAR
            </th>
            <th class="title">
              Round
            </th>
            <th class="title">
              Winnings
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="cell">
              {{ stats.DATE }}
            </td>
            <td class="cell">
              {{ stats.NAME }}
            </td>
            <td class="cell">
              {{ stats.POSITION }}
            </td>
            <td class="cell">
              {{ stats.SCORE }}
            </td>
            <td class="cell">
              {{ stats.VSPAR }}
            </td>
            <td class="cell">
              {{ stats.ROUNDS }}
            </td>
            <td class="cell">{{ stats.WINNINGS }} {{ stats.CURRENCY }}</td>
          </tr>
        </tbody>
      </table>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "playerCareerHigh",
  props: ["stats", "config"],
  data() {
    return {
      fields: [
        { key: "DATE", label: "Date" },
        { key: "NAME", label: "Name" },
        { key: "POSITION", label: "Pos" },
        { key: "SCORE", label: "Score" },
        { key: "VSPAR", label: "VSPAR", class: 'RoundsRemove'},
        { key: "ROUNDS", label: "Round", class: 'RoundsRemove'},
        { key: "WINNINGS", label: "Winnings" }
      ]
    };
  }
};
</script>

<style scoped>
::v-deep thead>tr>th{
  text-align: left;
  padding: 8px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border-top: 0px solid #dee2e6!important;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0.75rem;
  padding-left: 5px;
}
::v-deep thead>tr{
  background-color: #214232!important;
  color: white;
}
::v-deep tbody>tr>td{
  text-align: left;
  padding: 8px;
  /* border-top: 1px solid #fff; */
  font-size: 13px;
  color: #6C6C6C;
}
::v-deep tbody>tr{
  /* background-color: rgba(0,0,0,.05); */
}

::v-deep th:first-child{
  text-align: left;
}
::v-deep td:first-child{
  text-align: left;
  color: #f2af14;
  font-weight: 500;
  font-size: 13px;
}

::v-deep td:last-child{
  color: #3C3C3C;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  ::v-deep th.RoundsRemove {
    display: none;
  }
  ::v-deep td.RoundsRemove {
    display: none;
  }
}
</style>
