<template>
    <div class="">
        <div class="outerContainer">
            <b-container class="playerData">
                    <!-- <template v-if="enhancedPlayerOOM.length > 0">
                        <div class="one">
                            <span class="OOMPlayerNumber" v-bind:class="{oneDigit : enhancedPlayerOOM.length > 0 && enhancedPlayerOOM.length < 2, twoDigit : enhancedPlayerOOM.length > 1 && enhancedPlayerOOM.length < 3, threeDigit : enhancedPlayerOOM.length > 2 && enhancedPlayerOOM.length < 4 }">{{enhancedPlayerOOM}}</span>
                        </div>
                    </template> -->
                <b-row>
                    <template v-if="playerinfo.IMAGE == 'Y'">
                      <b-col class="col-md-4 profileTopimage playerImg" cols="12" lg='4' md="12" sm="12" :style="{ backgroundImage: 'url(' + DEFIMAGESAMS3 + 'sst' + '/media/photos/' + playerinfo.MEMBER.PHOTOS.PHOTO.PHOTO_IMAGE + ')' }">
                      </b-col>
                    </template>
                    <template v-else>
                      <b-col class="col-md-4 profileTopimage playerImg" cols="12" lg='4' md="12" sm="12" :style="{ backgroundImage: 'url(' + 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2023/02/Player_Sqaure_Grey.png' + ')' }">
                      </b-col>
                    </template>

                    <!-- <b-col class="col-md-4 profileTopimage" cols="12" lg='4' md="12" sm="12">
                      <template v-if="playerinfo.IMAGE == 'Y'">
                        <b-img
                            class="playerImg"
                            :src="DEFIMAGESAMS3 + '/' + 'sst' + '/media/photos/' +
                                playerinfo.MEMBER.PHOTOS.PHOTO.PHOTO_IMAGE
                            " onerror="javascript:this.src='https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/09/Avatar_Female.jpeg', this.alt='dummy', this.className='dummyImage'"
                        ></b-img>
                      </template>
                      <template v-else>
                        <b-img
                            class="playerImg"
                            :src="DEFIMAGESAMS3 + '/' + 'sst' + '/media/photos/' +
                                playerinfo.MEMBER.REFNO + '.jpg'
                            " onerror="javascript:this.src='https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/09/Avatar_Female.jpeg', this.alt='dummy', this.className='dummyImage'"
                        ></b-img>
                      </template>
                    </b-col> -->

                    <b-col cols="12" lg='7' class="playerTopData">
                        <b-row>
                            <b-col>
                                <span class="playerName">{{ playerinfo.MEMBER.FULL_NAME }} </span>
                                <span v-if="show_name_pronounce">
                                  <b-button @click="namePronounce()" class="namePronounce"
                                    ><font-awesome-icon
                                      class="icon"
                                      :icon="['fa', 'volume-up']"
                                  /></b-button>
                                </span>
                            </b-col>
                        </b-row>
                        <span class="mediaplayer-top">
                            <img
                                class="flagPlayerProfile"
                                :src="
                                (config.VUE_APP_FLAG_URL +
                                    playerinfo.MEMBER.NATIONALITY)
                                    | lowercase
                                "
                            />
                            <h3 class="mediaPlayerTopSec">{{ playerinfo.MEMBER.NATIONALITY }}</h3>
                        </span>
                        <hr class="mobHRHide">
                        <b-row class="playerProfileRow">
                            <b-col>
                                <span class="socialLinks">
                                    <span v-if="playerinfo.MEMBER.FACEBOOK.length">
                                        <b-nav-item
                                            :href="'https://facebook.com/' + playerinfo.MEMBER.FACEBOOK"
                                            class="socialLinkClass"
                                        >
                                            <span class="socailBlock">
                                            <img class="socialIcon" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Facebook_Green@2x-1.png">
                                            <h2 class="socialName">{{playerinfo.MEMBER.FACEBOOK}}</h2>
                                            </span>
                                        </b-nav-item>
                                    </span>

                                    <span v-if="playerinfo.MEMBER.TWITTER.length">
                                        <b-nav-item
                                            :href="'https://twitter.com/' + playerinfo.MEMBER.TWITTER"
                                            class="socialLinkClass"
                                        >
                                            <span class="socailBlock">
                                                <img class="socialIcon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2023/11/Twitter_Green.png">
                                                <h2 class="socialName">{{playerinfo.MEMBER.TWITTER}}</h2>
                                            </span>
                                        </b-nav-item>
                                    </span>

                                    <span v-if="playerinfo.MEMBER.INSTAGRAM.length">
                                        <b-nav-item
                                            :href="
                                            'https://instagram.com/' + playerinfo.MEMBER.INSTAGRAM
                                            "
                                            class="socialLinkClass"
                                        >
                                            <span class="socailBlock">
                                                <img class="socialIcon" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Instagram-_Green@2x-1.png">
                                                <h2 class="socialName">{{playerinfo.MEMBER.INSTAGRAM}}</h2>
                                            </span>
                                        </b-nav-item>
                                    </span>
                                </span>
                            </b-col>
                        </b-row>
                        <b-row class="bioSection" v-if="playerinfo.BIOGRAPHY.length">
                          <b-col class="bioColClass">
                            <p v-html="playerinfo.BIOGRAPHY" v-if="playerinfo.BIOGRAPHY.length"></p>
                            <p v-html="playerinfo.BIOGRAPHY2" v-if="playerinfo.BIOGRAPHY2.length"></p>
                            <p v-html="playerinfo.BIOGRAPHY3" v-if="playerinfo.BIOGRAPHY3.length"></p>
                            <p v-html="playerinfo.BIOGRAPHY4" v-if="playerinfo.BIOGRAPHY4.length"></p>
                          </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container> 
        </div>
    </div>
</template> 

<script>
export default {
  name: "Player Profile Top Information",
  components: {
  },
  props: [
    "refno",
    "season",
    "config",
    "playerinfo",
    "enhancedPlayerInfo",
    "enhancedPlayerOOM",
    "DEFIMAGESAMS3",
    "show_name_pronounce",
    "name_pronounce"
  ],
  data() {
    return {
    };
  },
  metaInfo() {
    return {
      title: this.enhancedPlayerInfo.full_name,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  methods: {
    namePronounce() {
      console.log("play name sound");
      var audio = new Audio(this.name_pronounce);
      audio.play();
    },
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
.bioColClass {
}
.row.bioSection {
  overflow: scroll;
  height: 100px;
  margin-top: 20px;
}
button.btn.namePronounce.btn-secondary {
  background-color: #006737;
  padding: 0.275rem 0.75rem;
}
.dummyImage {
  width: 100%;
}
.oneDigit {
  position: relative;
  left: -0px;
  top: 2px;
}
.twoDigit {
  position: relative;
  left: -4px;
  top: 2px;
}
.threeDigit {
  position: relative;
  left: -12px; 
  top: 2px;
}
.sD {
  white-space: nowrap;
  /* width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
.vl {
  border-left: 1px solid #EAEAEA;
  height: 215px;
}
.vl-two {
  border-left: 1px solid #EAEAEA;
  height: 340px;
  margin-top: -50px;
}
/* leftHandSide Stats */
span.LeftMidColor--9 {
  background-color: #F2AF14;
}
span.LeftMidColor--12 {
  background-color: #006737;
}
span.LeftMidColorValue--9 {
  color: #F2AF14;
}
span.LeftMidColorValue--12 {
  color: #006737;
}
::v-deep .barColor--9 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #F2AF14!important;
}
::v-deep .barColor--12 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #006737!important;
}
/* End leftHandSide Stats */

/* RightHandSide Stats */
span.RightMidColor--2 {
  background-color: #006737;
}
span.RightMidColorValue--2 {
  color: #006737;
}
::v-deep .barColorRight--2 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #006737!important;
}
span.RightMidColor--3 {
  background-color: #F2AF14;
}
span.RightMidColorValue--3 {
  color: #F2AF14;
}
::v-deep .barColorRight--3 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #F2AF14!important;
}
span.RightMidColor--4 {
  background-color: #D0D0D0;
}
span.RightMidColorValue--4 {
  color: #D0D0D0;
}
::v-deep .barColorRight--4 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #D0D0D0!important;
}
span.RightMidColor--5 {
  background-color: #204232;
}
span.RightMidColorValue--5 {
  color: #204232;
}
::v-deep .barColorRight--5 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #204232!important;
}
span.RightMidColor--6 {
  background-color: #F2AF14;
}
span.RightMidColorValue--6 {
  color: #F2AF14;
}
::v-deep .barColorRight--6 > .vue-simple-progress > .vue-simple-progress-bar {
  background-color: #F2AF14!important;
}
/* End RightHandSide Stats */

span.chart {
  margin-top: 12px;
  display: block;
}
.noRightFloat {
  float: none!important;
}
::v-deep .vue-simple-progress {
  height: 15px;
}
::v-deep .vue-simple-progress-bar {
  height: 15px!important;
}
/* ::v-deep span.dot {
  display: none;
}
::v-deep span.percent__dec {
  display: none;
} */
::v-deep span.percent_sign {
  display: none;
}
.MiddleSectionStats {
  margin-top: 50px;
  /* margin-bottom: 50px; */
}
.colorTwo {
  background-color: #006737!important;
}
.colorThree {
  background-color: #F2AF14!important;
}
.colorFour {
  background-color: #D0D0D0!important;
}
.backTwo {
  color: #006737!important;
}
.backThree {
  color: #F2AF14!important;
}
.backFour {
  color: #D0D0D0!important;
}
.BottomRowStats {
  margin-top: 40px;
}
.RightValueBottom {
  color: #23A769;
  font-size: 24px;
  font-weight: bold;
  margin-top: 4px;
  display: block;
  float: right;
}
.statLeftBottom {
  color: #6C6C6C;
  font-size: 13px;
  margin-left: 20px;
}
.hrLine {
  color: #EAEAEA;
  border-bottom: 1px solid;
  /* margin-top: 25px; */
}
.BestCareerPos {
  margin-left: 15px
}
.BestMoneyPos {
  margin-right: 15px
}
.rightBestMoney {
  width: 4px;
  height: 25px;
  background: #F2AF14;
  display: inline-block;
  border-radius: 2px;
  position: relative;
  top: 9px;
}
.leftCareerMoney {
  width: 4px;
  height: 25px;
  background: #006737;
  display: inline-block;
  border-radius: 2px;
  position: relative;
  top: 9px;
}
.leftstat {
  width: 4px;
  height: 25px;
  background: #23A769;
  display: inline-block;
  border-radius: 2px;
  position: relative;
  top: 9px;
}
.TitleBestMoney {
  display: block;
  color: #707070;
  font-size: 13px;
  margin-top: 60px;
  margin-bottom: 20px;
}
.TitleCareerMoney {
  display: block;
  color: #707070;
  font-size: 13px;
  margin-top: 60px;
  margin-bottom: 20px;
}
.colBestMoney {
  text-align: right;
}
.colCareerMoney {
  text-align: left;
}
span.playerCareerMoney {
  color: #006737;
  font-size: 40px;
  font-weight: bold;
}
span.playerBestMoney {
  color: #F2AF14;
  font-size: 40px;
  font-weight: bold;
}
.one.col-lg-1.col-12 {
  height: 20px;
  width: 6px;
  background: #f2af15;
  position: relative;
  right: -23px;
  z-index: 9999;
  text-align: center;
  top: 120px;
  padding-top: 5px;
  padding-bottom: 40px;
  border-radius: 38px;
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  max-width: 4.9%;
}
.topHeadersTable {
  background-color: #204232;
  position: absolute;
  left: 0;
  right: 0;
  height: 44px;
}
li.nav-item.socialLinkClass > a {
  padding: 0px;
}
span.socailBlock {
  display: inline-flex;
}
h2.socialName {
  color: #6C6C6C;
  font-weight: 100;
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 1.8;
  padding-left: 5px;
  padding-right: 10px;
}
img.socialIcon {
  width: 25px;
  height: 25px;
}
.profileTopimage {
  padding-right: 0px;
}
.playerTopData {
  background-color: #fff;
  border-radius: 0px 10px 10px 0px;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 40px;
  padding-bottom: 35px;
}
.outerContainer {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
  height: 520px;
  background-repeat: no-repeat;
  background-size: cover;
}
::v-deep table {
  width: 100%;
  z-index: 999;
  position: relative;
}
span.mediaplayer-top {
  display: flex;
  margin-top: 15px;
}
h3.mediaPlayerTopSec {
  color: #F2AF14;
  margin-left: 15px;
  font-weight: 500;
  margin-top: 8px;
  font-size: 16px;
}
::v-deep .tabR > div > ul > li:hover {
  background-color: #fff;
}
::v-deep .tabR > div > ul > li > a:hover {
  color: rgb(33 66 50)!important;
}
::v-deep .nav-link .active{
  background-color: #fff!important;
  color: rgb(33 66 50)!important;
}
.cList {
  background-color: #fff;
  border-radius: 10px;
  margin: 5px;
  flex: 0 0 32.333333%;
  max-width: 33.333333%;
  box-shadow: 3px 3px #e4e1e1;
}
.cDes {
  color: #006837;
  margin-top: 15px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
}
.cValue {
  color: #f2af15;
  font-size: 15pt;
  font-weight: 600;
  margin-bottom: 10px !important;
}
.playType{
  width: 40px;
  margin-top: 30px;
  float: right;
}
.tCont{
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  padding-top: 0px;
}
.playerprofile {
  padding-bottom: 50px;
}
.playerData {
  padding: 50px 0;
  width: 900px;
}
.titleCont {
  position: relative;
  top: 49%;
  transform: translateY(-50%);
  text-align: center;
}
li {
  list-style-type: none;
}

.playerName {
  width: 100%;
  display: block;
  margin-top: 1em;
  padding-bottom: 2px;
  font-size: 21px;
  color: #6C6C6C;
  font-weight: bold;
 
  text-transform: capitalize;
}
.flagPlayerProfile {
  max-width: 35px;
  height: 100%;
  margin-top: 4px;
  /* box-shadow: 0px 0px 3px #555; */
}
.icon {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.socialLinks {
  float: none;
  display: inline-flex;
  flex-wrap: wrap;
}
.playerProfileData {
  color: #939393;
  margin-left: 15px;
  font-weight: 100;
  margin-top: 13px;
  font-size: 18px;
  line-height: 25px;
}
.playerProfileTitle {
  color: #565656;
  font-size: 10pt;
  text-transform: capitalize;
  margin: 0;
}
.playerProfileRow {
  /* padding: 20px; */
  font-size: 20pt;
}
.playerImg {
  /* width: 100%; */
  /* width: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 0px 0px 10px;
  background-position: center;
}
.tabR {
  background-color: #006737!important;
}
::v-deep .nav-tabs{
  border: 0;
}
::v-deep .nav-tabs .nav-link {
  color: #fff !important;
  text-transform: capitalize;
  padding: 25px;
  font-size: 10px;
  padding-top: 35px;
  padding-bottom: 35px;
  display: block;
  border: 0;
  font-weight: bold;
}
::v-deep .nav-tabs .nav-link {
  margin-bottom: 0px;
}
::v-deep .nav-link.active {
  color: #fff !important;
  background-color: #204232;
  border-radius: 0;
  font-weight: bold;
}
::v-deep .tabR > div > ul > li > a:hover {
  color: #fff !important;
  background-color: #204232;
  border-radius: 0;
  font-weight: bold;
}
::v-deep .mt-3 {
  padding-top: 0px;
  /* background: url(https://updated.sunshinetour.com/wp-content/uploads/2021/04/SunshineTourBG_2-scaled-1.jpeg); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #fff;
}
::v-deep .mt-3, .my-3 {
  margin-top: 0rem!important;
}
@media only screen and (max-width: 991px) {
  .socialLinks {
    float: none;
    display: block;
  }
  ::v-deep .container.tabPlayerTab > ul > li {

  }
  ::v-deep .container.tabPlayerTab {
    max-width: 100%;
  }
  ::v-deep .nav-tabs .nav-link {
    color: #fff !important;
    text-transform: capitalize;
    padding: 0;
    font-size: 8px;
    padding-top: 25px;
    padding-bottom: 25px;
    display: block;
    border: 0;
    font-weight: bold;
    text-align: center;
  }
  ::v-deep .nav-tabs {
    border: 0;
    overflow: scroll;
    width: 100%;
    /* flex-wrap: nowrap; */
    display: block!important;
  }
  .outerContainer {
    height: auto;
  }
  .one {
    display: none;
  }
  .playerTopData {
    border-radius: 0px 0px 10px 10px;
  }
  .playerImg {
    height: 300px;
    width: 100%;
    border-radius: 10px 10px 0px 0px
  }
  .profileTopimage {
    padding-left: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .tabR {
    background-color: #006737!important;
    margin-top: -1px;
  }
  ::v-deep .container.tabPlayerTab {
    max-width: 100%;
    /* height: 49px; */
  }
  hr.mobHRHide {
    display: none;
  }
  .playerTopData {
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
  }
  .statLeftBottom {
    color: #6C6C6C;
    font-size: 13px;
    margin-left: 12px;
    margin-top: -18px;
    display: block;
  }
  span.playerCareerMoney {
    color: #006737;
    font-size: 25px;
    font-weight: bold;
  }
  span.playerBestMoney {
    color: #F2AF14;
    font-size: 25px;
    font-weight: bold;
  }
  .playerData {
    padding: 50px 30px;
    text-align: center;
    width: 100%;
  }
  span.mediaplayer-top {
    display: flex;
    margin-top: 0px;
    text-align: left;
    padding-left: 0px;
    margin-bottom: 40px;
  }
  ::v-deep .playerList > div > div > ul {
    display: -webkit-inline-box;
    text-align: center;
    /* padding-top: 15px;
    padding-bottom: 15px; */
  }
  ::v-deep .nav-tabs .nav-link {
    color: #fff;
    text-transform: capitalize;
    padding: 15px;
    font-size: 10pt;
    padding-top: 15px;
    padding-bottom: 15px;
    display: block;
    border: 0;
  }
  ::v-deep .cList {
    background-color: #fff;
    border-radius: 10px;
    margin: 5px;
    flex: 100%;
    max-width: 100%;
    box-shadow: 3px 3px #e4e1e1;
  }
  .playTypeImage.col-lg-4 {
    position: absolute;
  }
}
</style>