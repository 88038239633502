<template>
    <div class="MyprofileBack">
        <FanZoneHeader 
            :loginForename='loginForename'
            :token="token"
        />
        <b-container>
            <div class="spaceTop">
                <h1 class="Title">My Favourite Players</h1>
            </div> 
            <h3 class="messageText" v-show="updatedfavplayerInfo == ''">You have no favourite players currently saved</h3>
            <h3 class="messageText" v-if="status == 'OK'">{{message}}</h3>
            <b-row>

                <!-- FreshStart -->


                <!-- End of Fresh Start -->
                <!-- {{playerItems}} -->
                <b-col md="4" sm="12" v-for="(favplayer, index) in items" :key="index" class="favPlayerBlock" v-show="playerItems.includes(favplayer.REFNO)">
                    <div>
                        <div class="outerBody">
                            <div class="removePlayer" @click="removePlayer(favIndex, favplayer.REFNO)">Remove Player</div>
                            <div class="image" v-bind:style="{ backgroundImage: 'url(' + DEFIMAGESAMS3 + 'sst/media/photos/' + favplayer.REFNO + '.jpg' + ')' }"></div>
                            <div class="PlayerBody">
                                <div>
                                    <b-row class="rowFavPlayer">
                                        <b-col cols="2">
                                            <img
                                                class="flagPlayerProfile"
                                                :src="
                                                (config.VUE_APP_FLAG_URL +
                                                    favplayer.NAT)
                                                    | lowercase
                                                "
                                            />
                                        </b-col>
                                        <b-col cols="7">
                                            <span class="playerFullName">{{favplayer.FULL_NAME}}</span>
                                            <span class="tournStatsInfo">TOURNAMENT STATS</span>
                                        </b-col>
                                        <b-col cols="3">
                                            <img class="YellowStar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2023/08/yellow-star.png">
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <b-link :to="'/playerprofile/' + favplayer.REFNO">
                                <div class="SeeFullProfileBackColour">
                                    <span class="seeFullProfile">See Full Profile</span>
                                </div>
                            </b-link>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import apiCall from "@/services/api.js";
import axios from "axios"
import FanZoneHeader from '@/ocs/fanzone/components/fanZoneHeader.vue'
export default {
    name:"MyPlayers",
    props: ["config"],
    components: {
        FanZoneHeader
    },
    data(){
        return {
            wpInfo:[],
            wpInfoTitle: '',
            loginForename: '',
            loginEmail:'',
            items: [],
            favouritePlayer: [],
            fanUserData: {},
            email_string: "",
            post_string: "",
            playerID: '',
            'favPlayerRows': 0,
            fanloggedIn: localStorage.getItem("FanLoggedIn"),
            updatedfavplayer: {},
            removedPlayer: '',
            status:'',
            message:'',
            updatedfavplayerInfo: '',
            token:''
        }
    },
    metaInfo() {
        return {
            title: 'Fan Zone',
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
    methods: {
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        SelectPlayer: function() {
            let post_string = localStorage.getItem("Email")
            axios
            .post(
                this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=select&" + localStorage.getItem("Email") + "access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST + "token=" + this.token,
                post_string, {
                    headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then((response) => {
                this.loginForename = response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-forename']
                console.log("this.loginForename -- 59")
                console.log(this.loginForename)
                this.updatedfavplayer = response.data.user.interests.interest;
                this.favplayer.push(this.updatedfavplayer)
                console.log("this.favplayer")
                console.log(this.favplayer)
            })
        },
        removePlayer: function(favIndex, playerID) {
            this.removedPlayer = 'N';
            var playernum = playerID;
            console.log(favIndex)
            let EmailKey = localStorage.getItem("EmailKey")
            const dbse = "dev";
            const formData = [
                {'table': 'interests_' + dbse, 'name': 'email', 'value': this.loginEmail},
                {'table': 'interests_' + dbse, 'name': 'id', 'value': playernum},
                {'table': 'interests_' + dbse, 'name': 'type', 'value': 'PLAYER'},
            ];
            console.log("formData")
            console.log(formData)
            let post_string = '';

            for (let i = 0; i < formData.length; i++) {
                post_string += 'fields[' + i + '][table]=' + formData[i].table + '&';
                post_string += 'fields[' + i + '][name]=' + formData[i].name + '&';
                post_string += 'fields[' + i + '][value]=' + formData[i].value + '&';
            }
            this.email_string = "email=" + EmailKey + "&"
            post_string += this.email_string
            console.log("post_string")
            console.log(post_string)
            axios
            .post(
                this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=remove_fan&" + post_string + "access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST + "token=" + this.token,
                post_string, {
                    headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then((response) => {
                this.loginForename = response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-forename']
                console.log("this.favouritePlayer")
                console.log(this.favouritePlayer)  
                this.status = response.data.status;
                if (this.status == 'OK') {
                    this.message = 'Player has been removed from My Players'
                }
                setTimeout(function () {
                    this.message = ''
                }.bind(this), 5000)
                this.SelectPlayer();
                // alert("Player has been removed");
            })
        },
        playersList: function (){

        }
    },
    computed: {
        playerItems() {
            var updatedfavplayer = this.updatedfavplayer;
            var str = "";
            for (let i = 0; i < updatedfavplayer.length; i++) {
                str += updatedfavplayer[i]['interests_dev-id'] + "\n";
            }
            return str
        }
    },
    mounted() {
        let post_string = localStorage.getItem("Email")
        this.token = localStorage.getItem("Token")
        axios
        .post(
            this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=select&" + localStorage.getItem("Email") + "access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST + "token=" + this.token,
            post_string, {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        )
        .then((response) => {
            this.loginForename = response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-forename']
            this.loginEmail = response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-email']
            this.updatedfavplayer = response.data.user.interests.interest
            this.updatedfavplayerInfo = response.data.user.interests
            this.playersList();
        })
    },
    filters: {
        lowercase: function(value) {
        if (!value) {
            return "";
        }

        return value.toLowerCase() + ".svg";
        },
    },
    async created() {
        try {
        // First call, await for call to return
            let first_call = await apiCall.players();
            this.items = first_call.data.MEMBERS.PLAYER;
            // this.favouritePlayer = localStorage.getItem("Favname");
            this.DEFIMAGESAMS3 = first_call.data.GENERAL.DEFIMAGESAMS3;
            this.loading = false;
        } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
        }
    },
}
</script>

<style scoped>
h3.messageText {
    color: #6C6C6C;
    text-align: center;
    font-size: 15pt;
    margin-bottom: 30px;
}
h3.favPlayerText {
    color: #6C6C6C;
    font-size: 12px;
}
.removePlayer {
    cursor: pointer;
    width: 120px;
    background: #007741;
    color: #fff;
    float: right;
    border-radius: 0px 10px 0px 0px;
    padding: 5px;
    /* margin-right: 8px; */
    text-align: center;
    font-size: 10pt;
}
.removePlayer:hover {
    cursor: pointer;
    width: 120px;
    background: #F2AF14;
    color: #fff;
    float: right;
    border-radius: 0px 10px 0px 0px;
    padding: 5px;
    /* margin-right: 8px; */
    text-align: center;
    font-size: 10pt;
}
.YellowStar {
    width: 30px;
    margin: auto;
    display: block;
    margin-top: 5px;
}
.bodyPadStat {
    padding-left: 20px;
    padding-right: 20px;
}
.favPlayerBlock {
    margin-bottom: 30px;
}
.bodyStatData {
    color: #F2AF14;
    font-size: 38px;
    font-weight: bold;
    margin-top: -10px;
    float: right;
}
.bodyPad {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
}
.memberPlayedStat {
    color: #D0D0D0;
    font-size: 10px;
    display: block;
}
.memberStat {
    color: #6C6C6C;
    font-size: 12px;
    font-weight: bold;
}
.rowFavPlayer {
    padding-top: 30px;
    padding-bottom: 30px;
}
.tournStatsInfo {
    color: #D0D0D0;
    font-size: 11px;
    font-weight: 500;
    display: block;
}
img.flagPlayerProfile {
    width: 24px;
    height: 24px;
    margin-left: 20px;
    margin-top: 10px;
}
.playerFullName {
    font-size: 18px;
    color: #6C6C6C;
    font-weight: 600;
}
.SeeFullProfileBackColour {
    background-color: #027741;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 15px;
}
.SeeFullProfileBackColour:hover {
    background-color: #F2AF14;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 15px;
}
.seeFullProfile {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
}
.PlayerBody {
    background-color: #fff;
}
.image {
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px 10px 0px 0px;
}
.MyprofileBack {
    background-color: #F2F2F2!important;
    padding-bottom: 30px;
}
.spaceTop {
    margin-top: 30px;
}
h1.Title {
    color: #6c6c6c;
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
    padding-bottom: 25px;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 30px;
}
.whiteBlock {
    padding-top: 50px;
    padding-bottom: 50px;
}
.GreyBlock {
    padding-top: 50px;
    padding-bottom: 50px;
}
.SecondSection {
    background-color: #F2F2F2;
}
.sectionBackground {
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    background-position: center;
}
.SponRow {
    margin: auto;
    text-align: center;
    /* display: table; */
}
::v-deep .conHeader.rowTwoContact.col-lg-6 > p {
    margin-bottom: 0px;
}
.rowTwoContact {
    margin-top: 60px;
}
.contactMargin {
    margin-top: 50px;
    margin-bottom: 50px;
}
.contactBack {
    background-color: #F0F0F0;
}
.contactuss {
    display: none;
}
::v-deep p {
    color: #6C6C6C;
    font-size: 13px;
    line-height: 1.8;
}
::v-deep .conHeader > p > strong {
    color: #006737;
    font-size: 14px;
   
}
::v-deep .form-control {
    font-size: 13px;
    /* color: #D0D0D0; */
    border-radius: 0px;
}
::v-deep .btn-primary {
    color: #fff;
    background-color: #006737;
    border-color: #006737;
    width: 200px;
    border-radius: 0px;
    font-size: 11px;
    font-weight: bold;
    text-transform: capitalize;
}
::v-deep .btn-primary:hover {
    color: #fff;
    background-color: #f2af14;
    border-color: #f2af14;
    width: 200px;
}
.flip-card-back {
    padding: 30px;
}
.learnMore {
    text-align: center;
    border: 1px solid;
    padding: 10px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    height: 30px;
    line-height: 8px;
}
.learnMore:hover {
    background-color: #f2af14;
}
.learnMore > a {
    color: #fff!important;
}
h2.sponsorHead {
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    text-align: left;
}
p.sponsorText {
    text-align: left;
    color: #fff!important;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    height: 113px;
}
::v-deep p.sponsorText > p {
    color: #fff!important;
    font-size: 12px;
}
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 247px;
    /* height: 255px; */
    /* height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    /* background-color: #bbb; */
    color: black;
}

.flip-card-back {
    background-color: #006737;
    color: white;
    transform: rotateY(180deg);
}
.sponsorImage {
    width: 100%;
    margin-bottom: 30px;
}
.sponsorImage:hover {
    /* background-color: #f2af14; */
}
span.description {
    color: #6C6C6C;
    font-size: 13px;
    margin-top: 30px;
    display: block;
}
p.infoLine {
    color: #6C6C6C;
    font-size: 13px;
}
p.titleHeader {
    color: #6C6C6C;
    font-weight: bold;
    font-size: 13px;
}
h2.howtoBuy {
    color: #6C6C6C;
    font-size: 21px;
    font-weight: bold;
    text-transform: capitalize;
    padding-bottom: 20px;
}
.sunboysImage {
    width: 100%;
    background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/08/Screenshot-2022-07-11-at-13.36.26.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 306px;
    background-position: right;
}
.howToBuy {
    background-color: #F2F2F2;
    padding-top: 100px;
    padding-bottom: 100px;
}
.FirstP {
    margin-top: 50px!important;
    margin-bottom: 60px!important;
}
.aboutUsImage {
    width: 100%;
}
.sectionP {
    color: #6C6C6C;
    font-size: 13px;
    margin-bottom: 30px;
    margin-top: 20px;
}
h2.sunboys {
    color: #6C6C6C;
    text-transform: capitalize;
    font-size: 21px;
    font-weight: bold;
}
::v-deep label {
    width: 100%;
}
::v-deep textarea.wpcf7-form-control.wpcf7-textarea {
    width: 100%!important;
    border: 1px solid #ced4da;
}
::v-deep input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required {
    width: 100%!important;
    border: 1px solid #ced4da;
    height: 35px;
}
::v-deep div#wpcf7-f34329-o1 {
    margin-top: -15px;
    display: inline-block;
    width: 100%!important;
}
.title{
    color: #6C6C6C;
    text-transform: capitalize;
    font-size: 21px;
    font-weight: bold;
    margin-top: 40px;
   
}
::v-deep  a{
    color: #f2af14!important;
}
.content{
    padding-bottom: 50px;
    padding-top: 30px;
}
::v-deep .wpcf7-submit{
    width: 100%;
    background-color: #f2af14;
    color: #fff;
    border: 0;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
}



@media screen and (max-width: 768px){
    .IframeCode {
        width: 100%;
    }
    .IframeCode > iframe {
        width: 100%;
    }
    .sponsorImage {
        width: 100%;
        margin-bottom: 15px;
    }
    .sponBack.col-sm-6.col-md-6.col-lg-6.col-6 {
        padding-left: 7px;
        padding-right: 7px;
    }
    .SponRow {
        margin: auto;
        text-align: center;
        display: inline-flex;
    }
    ::v-deep .btn-primary {
        color: #fff;
        background-color: #006737;
        border-color: #006737;
        width: 100%!important;
        border-radius: 0px;
        font-size: 11px;
        font-weight: bold;
        text-transform: capitalize;
    }
    ::v-deep .btn-primary:hover {
        color: #fff;
        background-color: #f2af14;
        border-color: #f2af14;
        width: 100%!important;
    }  
    .rowTwoContact {
        margin-top: 0px;
        margin-bottom: 60px;
    } 
    .conHeader.col-lg-6 {
        width: 50%;
    }
    .mobileContactBlock {
        margin-left: 15px;
    }
    .title {
        color: #6C6C6C;
        text-transform: capitalize;
        font-size: 21px;
        font-weight: bold;
        margin-top: 40px;
        padding-left: 0px;
       
    }
}
</style>