<template>
  <div>
    <b-row class="Desk">
        <b-col class="mobCol" lg="4" cols="4">          
          <a class="playertopSizes" :href="'/playerprofile/' + data[0].playerno">
            <b-img class="oomTopImg" :src="DEFIMAGESAMS3 + 'sst' + '/media/photos/' + data[0].playerno + '.jpg'"></b-img>
          </a>
          <a :href="'/playerprofile/' + data[0].playerno" class="playerLink">
            <b-row class="bottomOom">
              <b-col lg='12'>
                <span class='homePos'>
                  <span class="innerPos">{{data[0].pos}}</span>
                </span>
              </b-col>
              <b-col lg='12'>
                <b-row>
                  <b-col cols="12" lg="2" md="2" sm="2">
                    <b-img class='playerFlag' :src="
                      (config.VUE_APP_FLAG_URL +
                        data[0].nationality)
                        | lowercase
                    "></b-img>
                  </b-col>
                  <b-col cols="12" lg="10" md="10" sm="10">
                    <span class='infoBlock'>
                      <p class='topOomName'>
                        {{data[0].name}}
                      </p>
                      <p class='topOomName oomValue'>
                        {{data[0].oom_value}}
                      </p>
                      <p class='tournsPlayed'>
                        <span>Tournaments: </span>{{data[0].tournaments_played}}
                      </p>
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </a>
        </b-col>
        <b-col class="mobCol" lg="4" cols="4">          
          <a class="playertopSizes" :href="'/playerprofile/' + data[1].playerno">
            <b-img class="oomTopImg" :src="DEFIMAGESAMS3 + 'sst' + '/media/photos/' + data[1].playerno + '.jpg'"></b-img>
          </a>
          <a :href="'/playerprofile/' + data[1].playerno" class="playerLink">
            <b-row class="bottomOom">
              <b-col lg='12'>
                <span class='homePos'>
                  <span class="innerPos">{{data[1].pos}}</span>
                </span>
              </b-col>
              <b-col lg='12'>
                <b-row>
                  <b-col cols="12" lg="2" md="2" sm="2">
                    <b-img class='playerFlag' :src="
                      (config.VUE_APP_FLAG_URL +
                        data[1].nationality)
                        | lowercase
                    "></b-img>
                  </b-col>
                  <b-col cols="12" lg="10" md="10" sm="10">
                    <span class='infoBlock'>
                      <p class='topOomName'>
                        {{data[1].name}}
                      </p>
                      <p class='topOomName oomValue'>
                        {{data[1].oom_value}}
                      </p>
                      <p class='tournsPlayed'>
                        <span>Tournaments: </span>{{data[1].tournaments_played}}
                      </p>
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </a>
        </b-col>
        <template>
          <b-col class="mobCol" lg="4" cols="4">
            <a class="playertopSizes" :href="'/playerprofile/' + data[2].playerno">
              <b-img class="oomTopImg" :src="DEFIMAGESAMS3 + 'sst' + '/media/photos/' + data[2].playerno + '.jpg'"></b-img>
            </a>
            <a :href="'/playerprofile/' + data[2].playerno" class="playerLink">
              <b-row class="bottomOom">
                <b-col lg='12'>
                  <span class='homePos'>
                    <span class="innerPos">{{data[2].pos}}</span>
                  </span>
                </b-col>
                <b-col lg='12'>
                  <b-row>
                    <b-col cols="12" lg="2" md="2" sm="2">
                      <b-img class='playerFlag' :src="
                        (config.VUE_APP_FLAG_URL +
                          data[2].nationality)
                          | lowercase
                      "></b-img>
                    </b-col>
                    <b-col cols="12" lg="10" md="10" sm="10">
                      <span class='infoBlock'>
                        <p class='topOomName'>
                          {{data[2].name}}
                        </p>
                        <p class='topOomName oomValue'>
                          {{data[2].oom_value}}
                        </p>
                        <p class='tournsPlayed'>
                          <span>Tournaments: </span>{{data[2].tournaments_played}}
                        </p>
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </a>
          </b-col>
        </template>
    </b-row>
    <b-row class="Mob">
      <b-col class="mobCol" cols="12" lg="4" md="4" sm="12">          
        <a class="playertopSizes" :href="'/playerprofile/' + data[0].playerno">
          <b-img class="oomTopImg" :src="DEFIMAGESAMS3 + 'sst' + '/media/photos/' + data[0].playerno + '.jpg'"></b-img>
          <b-row class="bottomOom">
            <b-col lg='12'>
              <span class='homePos'>
                <span class="innerPos">{{data[0].pos}}</span>
              </span>
            </b-col>
            <b-col lg='12'>
              <b-row>
                <b-col lg="2" md="2" sm="2" class="mobFlag">
                  <b-img class='playerFlag' :src="
                    (config.VUE_APP_FLAG_URL +
                      data[0].nationality)
                      | lowercase
                  "></b-img>
                </b-col>
                <b-col lg="10" md="10" sm="10" class="mobInfo">
                  <span class='infoBlock'>
                    <p class='topOomName'>
                      {{data[0].name}}
                    </p>
                    <p class='topOomName oomValue'>
                      {{data[0].oom_value}}
                    </p>
                    <p class='tournsPlayed'>
                      <span>Tournaments: </span>{{data[0].tournaments_played}}
                    </p>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </a>
      </b-col>
      <b-col class="mobCol" cols="12" lg="4" md="4" sm="12">
        <a class="playertopSizes" :href="'/playerprofile/' + data[1].playerno">
          <b-img class="oomTopImg" :src="DEFIMAGESAMS3 + 'sst' + '/media/photos/' + data[1].playerno + '.jpg'"></b-img>
          <b-row class="bottomOom">
            <b-col lg='12'>
              <span class='homePos'>
                <span class="innerPos">{{data[1].pos}}</span>
              </span>
            </b-col>
            <b-col lg='12'>
              <b-row>
                <b-col lg="2" md="2" sm="2" class="mobFlag">
                  <b-img class='playerFlag' :src="
                    (config.VUE_APP_FLAG_URL +
                      data[1].nationality)
                      | lowercase
                  "></b-img>
                </b-col>
                <b-col lg="10" md="10" sm="10" class="mobInfo">
                  <span class='infoBlock'>
                    <p class='topOomName'>
                      {{data[1].name}}
                    </p>
                    <p class='topOomName oomValue'>
                      {{data[1].oom_value}}
                    </p>
                    <p class='tournsPlayed'>
                      <span>Tournaments: </span>{{data[0].tournaments_played}}
                    </p>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </a>
      </b-col>
      <b-col class="mobCol" cols="12" lg="4" md="4" sm="12">
        <a class="playertopSizes" :href="'/playerprofile/' + data[2].playerno">
          <b-img class="oomTopImg" :src="DEFIMAGESAMS3 + 'sst' + '/media/photos/' + data[2].playerno + '.jpg'"></b-img>
          <b-row class="bottomOom">
          <b-col lg='12'>
            <span class='homePos'>
              <span class="innerPos">{{data[2].pos}}</span>
            </span>
          </b-col>
          <b-col lg='12'>
            <b-row>
              <b-col lg="2" md="2" sm="2" class="mobFlag">
                <b-img class='playerFlag' :src="
                  (config.VUE_APP_FLAG_URL +
                    data[2].nationality)
                    | lowercase
                "></b-img>
              </b-col>
              <b-col lg="10" md="10" sm="10" class="mobInfo">
                <span class='infoBlock'>
                  <p class='topOomName'>
                    {{data[2].name}}
                  </p>
                  <p class='topOomName oomValue'>
                    {{data[2].oom_value}}
                  </p>
                  <p class='tournsPlayed'>
                    <span>Tournaments: </span>{{data[2].tournaments_played}}
                  </p>
                </span>
              </b-col>
            </b-row>
          </b-col>
          </b-row>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "topThree",
  props: ["data", "config", "sponsor", "stat", "fullname", "DEFIMAGESAMS3"],
    filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
.infoBlock:hover {
  padding-top: 20px;
  padding-bottom: 30px;
  display: block;
  padding-left: 20px;
  text-decoration: underline;
  color: #fff;
}
.playerLink:hover > .bottomOom{

}
.sponsorImage {
  width: 100%;
}
.infoBlock {
  padding-top: 20px;
  padding-bottom: 30px;
  display: block;
  padding-left: 20px;
}
.tournsPlayed {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 100;
  margin-bottom: 0!important;
  padding-right: 20px;
}
.oomValue {
  color: #F2AF14!important;
  font-size: 14px;
}
span.innerPos {
  margin-top: 10px;
  display: inline-block;
  font-size: 21px;
}
.oomTopImg {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 220px;
}
.bottomOom {
  background-color: #006837;
  border-radius: 0 0 0px 0px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 10px;
  margin-bottom: 15px;
}
.homePos{
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin: auto;
  display: block;
  background: #f2af15;
  width: 50px;
  border-radius: 40px;
  height: 50px;
  margin-top: -40px;
}
.topOom{
  float: right;
  display: -webkit-box;
}
.topOomName{
  margin-bottom: 0!important;
  padding-right: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.playerFlag{
  width: 35px;
  height: 35px;
  background: black;
  border-radius: 30px;
  margin-top: 32px;
  margin-left: 20px;
}
::v-deep .col:nth-child(2){
  /* transform: scale(1.13); */
  z-index: 99;
  /* margin-top: -14px;  */
}
@media only screen and (min-width: 991px) {
  .Mob {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  ::v-deep .mobCol {
    width: 100%;
    display: block;
  }
  .bottomOom {
    width:100%;
  }
  .Desk {
    display: none;
  }
  .tThree {
    padding: 30px 0;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 768px) {
  .infoBlock {
    padding-top: 20px;
    padding-bottom: 30px;
    display: block;
    padding-left: 20px;
    text-align: left;
  }
  .btn-group {
    width: 100%;
    margin-bottom: 15px;
  }
  .topThree {
    padding: 15px;
    margin: auto;
    text-align: center;
  }
  .row.Mob {
    display: -webkit-inline-box;
    overflow: scroll;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
  }
  .mobCol {
    /* width: 100%; */
    width: 350px;
    display: block;
    overflow-x: auto;
    /* white-space: nowrap; */
    /* display: block; */
    float: none;
    display: inline-block;
  }
}

@media only screen and (max-width: 500px) {
  .mobFlag {
    width: 20%;
  }
  .mobInfo {
    width: 80%;
  }
}
</style>
