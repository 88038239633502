<template>
  <div>
    <div class="sectionBackground">
      <ScheduleInfo
        :data="data.tournaments.tournaments_entry"
        :tm_params="tm_params"
        :id="id"
        :loggedin='PlayerLogged'
        :idName="idName"
        :code="this.$route.query.code"
        :changeYear="changeYear"
        :fullData="data"
        :PlayerLogged='PlayerLogged'
        :DEFIMAGESAMS3="DEFIMAGESAMS3"
      />
    </div>
    <b-container>
      <div class="groupDropdown">
          <b-row>
              <b-col>
                  <h2 class="UpcomingTouraments">Upcoming Tournaments</h2>
              </b-col>
              <b-col>
                  <b-nav class="dropdownSched">
                  <b-dropdown :text="this.id + ' Season'">
                      <b-dropdown-item
                      class="textPos"
                      v-on:click="changeYear"
                      v-for="(seasons,
                      index) in data.seasons.seasons_entry.slice().reverse()"
                      :key="index"
                      :title="seasons.code"
                      :year="seasons.code"
                      >{{ seasons.desc }}</b-dropdown-item
                      >
                  </b-dropdown>
                  </b-nav>
              </b-col>
          </b-row>
          <b-row v-if="PlayerLogged !== 'guest'">
              <b-col lg="4" v-if="data.tm_params.season_files !== null">
                  <b-nav class="dropdownSched memFloat">
                  <b-dropdown text="Member Season Documents" v-if="Array.isArray(data.tm_params.season_files.season_file_details)">
                      <b-dropdown-item
                      class="textPos"
                      v-for="(facts, index) in data.tm_params.season_files.season_file_details"
                      :key="index"
                      title="Member Season Documents"
                      :href="'https://sunshinetour.info/factsheets/' + id + '/' + facts.season_file" target="_blank" 
                      >{{ facts.season_desc }}</b-dropdown-item
                      >
                  </b-dropdown>
                  <b-dropdown text="Member Season Documents" v-else>
                      <b-dropdown-item
                      class="textPos"
                      v-for="(facts, index) in data.tm_params.season_files"
                      :key="index"
                      title="Member Season Documents"
                      :href="'https://sunshinetour.info/factsheets/' + id + '/' + facts.season_file" target="_blank" 
                      >{{ facts.season_desc }}</b-dropdown-item
                      >
                  </b-dropdown>
                  </b-nav>
              </b-col>
              <b-col lg="5" class='memberButtons'>
                  <b-button
                  class="memLink"
                  variant="primary"
                  href="https://ocs-africa.com/sst-members/"
                  target="_blank"
                  >Secure Members Area</b-button
                  >
                  <b-button
                  class="memLink"
                  variant="primary"
                  :to="{
                      name: 'anti-doping',
                      query: {
                      pageId: 25872,
                      },
                  }"
                  >Anti-Doping</b-button
                  >
                  <b-button
                  class="memLink"
                  variant="primary"
                  href="https://www.cognitoforms.com/IGTGolf1/SunshineTourGermFreeOrderForm"
                  target="_blank"
                  >GermFree24</b-button
                  >
              </b-col>
              <b-col lg="3" v-if="data.tm_params.g_fact_sheets !== null">
                  <b-nav class="dropdownSched">
                  <b-dropdown text="Member Documents" v-if="Array.isArray(data.tm_params.g_fact_sheets.file_details)">
                      <b-dropdown-item
                      class="textPos"
                      v-for="(facts, index) in data.tm_params.g_fact_sheets.file_details"
                      :key="index"
                      title="Member Documents"
                      :href="'https://sunshinetour.info/factsheets/' + facts.file" target="_blank" 
                      >{{ facts.desc }}</b-dropdown-item
                      >
                  </b-dropdown>
                  <b-dropdown text="Member Documents" v-else>
                      <b-dropdown-item
                      class="textPos"
                      v-for="(facts, index) in data.tm_params.g_fact_sheets"
                      :key="index"
                      title="Member Documents"
                      :href="'https://sunshinetour.info/factsheets/' + facts.file" target="_blank" 
                      >{{ facts.desc }}</b-dropdown-item
                      >
                  </b-dropdown>
                  </b-nav>
              </b-col>
          </b-row>
      </div>
    </b-container>
    <ScheduleTable
      :data="data.tournaments.tournaments_entry"
      :fields="fields"
      :id="id"
      :loggedin='PlayerLogged'
      :idName="idName"
    />
  </div> 
</template>

<script>
import apiCall from "@/services/api.js";
import ScheduleTable from "@/components/scheduleTable.vue";
import ScheduleInfo from "@/components/scheduleInfo.vue";
export default {
  name: "schedule",
  props: ["loggedin", "config"],
  components: {
    ScheduleTable,
    ScheduleInfo
  },
  data() {
    return {
      id: [],
      tm_params: [],
      data: [],
      code: this.$route.query.code,
      fields: [
        { key: "dates", label: "Dates", class: "tableTh" },
        { key: "full_name", label: "Tournament", class: "tableTh" },
        { key: "sponsor_logo", label: "", class: "tableTh" },

        { key: "rounds", label: "Rounds", class: "tableTh" },
        { key: "total_prize_fund", label: "Prize Money", class: "tableTh" },
      ],
    };
  },
  metaInfo() {
    return {
      title: 'Schedule',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  computed: {
    PlayerLogged: function(player) {
      if (this.loggedin === "" || this.loggedin.status === null) player = "guest";
      else player = this.loggedin.playerID;
      this.login();
      return player;
    },
    memberSheets: function(sheets) {
      if (this.loggedin === "") sheets = "";
      else
        sheets = this.data.fact_sheets.fact_sheet.filter(
          (sheet) => sheet.fact_sheet_desc.length
        );
      return sheets;
    },
  },
  methods: {
    futureTours() {
      var data = this.data.tournaments.tournaments_entry;
      return data
        .filter((upcomingTours) => upcomingTours.days_away.indexOf("-"))
        .slice(0, 3);
    },
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
      return (
        (this.id = id),
      apiCall
      .tmParamsYear(id)
      .then(({ data }) => {
        this.data = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      })
      );
    },
    async login() {
      try {
        // First call, await for call to return
        let first_call = await apiCall.tmParamsBase();
        this.tm_params = first_call.data;
        this.id = first_call.data.tm_params.season_code;
        this.idName = first_call.data.tm_params.season_name;
        this.loading = false;
        // Second call, await for call to return
        let second_call = await apiCall.tmParamsPlayer(
          this.id,
          this.loggedin.playerID,
          this.code
        );
        this.data = second_call.data;
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
    async notLogin() {
      try {
        // First call, await for call to return
        let first_call = await apiCall.tmParamsBase();
        this.tm_params = first_call.data;
        this.id = first_call.data.tm_params.season_code;
        this.DEFIMAGESAMS3 = first_call.data.tm_params.DEFIMAGESAMS3;
        this.loading = false;
        // Second call, await for call to return
        let second_call = await apiCall.tmParamsYear(this.id);
        this.data = second_call.data;
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
  },
  mounted() {
    if (this.loggedin.status === null || this.loggedin === '') {
      this.notLogin()
    } else {
      this.login()
    }
  },
};
</script>

<style scoped>
::v-deep li.textPos > a {
  color: #000!important;
}
::v-deep .dropdown-toggle {
  color: #6C6C6C;
  background-color: white;
  border: 1px solid black;
  border-radius: 0;
  width: 289px;
  text-align: left;
  font-weight: bold;
}
::v-deep .dropdown-toggle::after {
  float: right;
  margin-top: 10px;
}
::v-deep .dropdown-toggle:hover {
  color: #263056;
  background-color: white;
}
.groupDropdown {
  margin-top: 6em;
}
ul.nav.dropdownSched.memFloat {
  float: left;
}
::v-deep ul.nav.dropdownSched > div > .dropdown-toggle {
  line-height: 28px;
  height: 38px;
  border-radius: 0px;
  border: 1px solid #E0E0E0;
  font-weight: 400;
  font-size: 10pt;
}
.dropdownSched {
  /* padding: 10px 0; */
  float: right;
}
h2.UpcomingTouraments {
  color: #6C6C6C;
  font-size: 21px;
  font-weight: bold;
  text-transform: capitalize;
}
h2.UpcomingTouraments {
  color: #6C6C6C;
  font-size: 21px;
  font-weight: bold;
  text-transform: capitalize;
}
.sectionBackground {
  background: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
  background-repeat: no-repeat;
  padding-bottom: 7em;
  padding-top: 5em;
  background-size: cover;
}
@media only screen and (max-width: 767px) {
  ul.nav.dropdownSched.memFloat {
    float: left;
    margin-bottom: 20px;
    width: 100%;
  }
  .dropdownSched {
    float: none;
    margin-top: 20px;
    width: 100%;
    display: inline-grid;
  }
  .groupDropdown {
    margin-top: 2em;
  }
  .memberButtons{
    margin-top: 5px;
    margin-left: 0px!important;
    display: inline-grid;
  }
  .memberButtons > a{
    margin-bottom: 10px;
  }
  .sectionBackground {
    background: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
    background-repeat: no-repeat;
    padding: 1em;
    background-size: cover;
  }
}

</style>
