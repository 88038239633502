<template>
  <div style="padding-top:50px;" class="mobileTop">
    <div>
      <!-- <b-row v-for="(month, index) in months" :key="index" class="monthBreakBack">
        <b-container>
          <span class="monthBreak">{{month.name.substring(0,3)}} {{new Date().getFullYear()}}</span>
        </b-container>
      </b-row> -->
      <b-container>
        <b-tabs content-class="mt-3" class="tabClass">
          <b-tab title="Past" class="nonactive" :id="past">
            <div class="rowItem container past">
              <b-row
                v-for="(allTours, index) in data"
                :key="index"
                class="tourRow"
                v-show="allTours.days_away < 0"
              >
                <b-col
                  lg="2"
                  md="2"
                  class="tourDates"
                  :class="{
                    current_tourn_row: allTours.code == $route.query.code
                  }"
                >
                  <div class="posTop" :class="{ major: allTours.major == 'Y' }">
                    <div style="color: #F2AF14!important">{{ allTours.dates }}</div>
                    <span class="hideDesk">
                      <b-button
                        :to="{
                          name: 'tournament',
                          query: {
                            id: id,
                            code: allTours.code
                          }
                        }"
                        class="mobtournInfo"
                      >
                        <font-awesome-icon
                          class="icon"
                          :icon="['fa', 'chevron-right']"
                        />
                      </b-button>
                    </span>
                  </div>
                </b-col>
                <b-col lg="3" md="3">
                  <div class="posTop">
                    <b-link
                      class="nameLink"
                      :to="{
                        name: 'tournament',
                        query: {
                          id: id,
                          code: allTours.code
                        }
                      }"
                    >
                      <div
                        class="tourTitleMore"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        {{ allTours.full_name }}
                      </div>
                      <p
                        class="courseTour"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        {{ allTours.course }}
                      </p>
                    </b-link>
                  </div>
                </b-col>
                <template v-if="loggedin === 'guest'">
                  <b-col lg="1" md="1">
                    <div class="posTop">
                      <div class="courseTour">
                        Rounds
                        <span
                          class="hideDesk mobvalue"
                          :class="{ major: allTours.major == 'Y' }"
                          >{{ allTours.rounds }}</span
                        >
                      </div>
                      <div class="courseTour">
                        Prize Fund
                        <span
                          class="hideDesk mobvalue"
                          v-if="allTours.total_prize_fund.length > 0"
                          :class="{ major: allTours.major == 'Y' }"
                        >
                          {{ allTours.total_prize_fund }}</span
                        >
                        <span
                          class="hideDesk mobvalue"
                          v-else
                          :class="{ major: allTours.major == 'Y' }"
                        >
                          N/A</span
                        >
                      </div>
                    </div>
                  </b-col>
                  <b-col lg="2" md="2" class="mobHide">
                    <div class="posTop">
                      <p
                        class="tourTitleMoreindi"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        {{ allTours.rounds }}
                      </p>
                      <p
                        class="tourTitleMoreindi"
                        v-if="allTours.total_prize_fund.length > 0"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        {{ allTours.total_prize_fund }}
                      </p>
                      <p
                        v-else
                        class="tourTitleMoreindi"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        N/A
                      </p>
                    </div>
                  </b-col>
                </template>
                <template v-else>
                  <b-col lg="1" md="1">
                    <div class="posTop">
                      <div class="courseTour">
                        Entries Close
                      </div>
                      <p class="tourTitleClosed">
                        <span v-if="allTours.entry_closed === 'Y'">
                          Entries Closed
                        </span>
                        <span
                          v-else
                          class="open"
                          :class="{ major: allTours.major == 'Y' }"
                        >
                          {{ allTours.closing_date }}-{{
                            allTours.closing_time
                          }}
                        </span>
                      </p>
                    </div>
                  </b-col>
                  <b-col lg="2" md="2">
                    <div class="posTop">
                      <div class="courseTour">
                        Entry Status
                      </div>
                      <p
                        class="tourTitleClosed"
                        v-if="allTours.entry_status === 'X'"
                      >
                        Not Entered
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'E'"
                        class="tourTitleEntered"
                      >
                        Entered
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'W'"
                        class="tourTitleClosed"
                      >
                        Withdrawn
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'R'"
                        class="tourTitleReserve"
                      >
                        Reserve
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'C'"
                        class="tourTitleCut"
                      >
                        Cut
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'L'"
                        class="tourTitleEntered"
                      >
                        Late Entry
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'Q'"
                        class="tourTitleQualify"
                      >
                        Required to Qualify
                      </p>
                    </div>
                  </b-col>
                </template>
                <b-col lg="2" md="2" class="mobHide">
                  <b-img
                    v-if="allTours.sponsor_logo.length"
                    class="tourSponLogo"
                    :src="allTours.sponsor_logo"
                  ></b-img>
                </b-col>
                <b-col lg="2" md="2" class="mobHide">
                  <div class="posTop">
                    <b-link
                      :to="{
                        name: 'tournament',
                        query: {
                          id: id,
                          code: allTours.code
                        }
                      }"
                      class="tourInfoBtn"
                    >
                      <span class="tournInfo">
                        Tournament Info
                        <span class="iconRight">
                          <font-awesome-icon
                            class="icon"
                            :icon="['fa', 'chevron-right']"
                          />
                        </span>
                      </span>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab title="Future" class="nonactive" active :id="future">
            <!-- <h2 v-show="data == ''">No Future Tournaments</h2> -->
            <!-- <template v-for="(month, index) in months"> -->
            <template>
              <!-- <div :key="index" class="outerMonth-BK" :class="classHide">
                <div :key="index">
                  <span :key="index" class="monthBreak container">{{month.name}}</span>
                </div>
              </div> -->
              <!-- <b-row v-for="(allTours, index) in data" :key="index" class="tourRow" v-show="allTours.days_away > 0 && allTours.dates.includes(month.name)"> -->
              <b-row
                v-for="(allTours, index) in data"
                :key="index"
                class="tourRow"
                v-show="allTours.days_away > 0 || allTours.days_away == 0"
              >
                <!-- <b-col lg="12" :class="'removeMonth' + index" class="monthStyle">
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('January')">January</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('February')">February</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('March')">March</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('April')">April</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('May')">May</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('June')">June</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('July')">July</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('August')">August</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('September')">September</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('October')">October</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('November')">November</span>
                  <span v-if="allTours.days_away > 0 && allTours.dates.includes('December')">December</span>
                </b-col> -->
                <b-col
                  lg="2"
                  md="2"
                  class="tourDates"
                  :class="{
                    current_tourn_row: allTours.code == $route.query.code
                  }"
                >
                  <div class="posTop" :class="{ major: allTours.major == 'Y' }">
                    <div style="color: #F2AF14!important">
                      {{ allTours.dates }}
                    </div>
                    <span class="hideDesk">
                      <b-button
                        :to="{
                          name: 'tournament',
                          query: {
                            id: id,
                            code: allTours.code
                          }
                        }"
                        class="mobtournInfo"
                      >
                        <font-awesome-icon
                          class="icon"
                          :icon="['fa', 'chevron-right']"
                        />
                      </b-button>
                    </span>
                  </div>
                </b-col>
                <b-col lg="3" md="3">
                  <b-link
                    class="nameLink"
                    :to="{
                      name: 'tournament',
                      query: {
                        id: id,
                        code: allTours.code
                      }
                    }"
                  >
                    <div class="posTop">
                      <div
                        class="tourTitleMore"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        {{ allTours.full_name }}
                      </div>
                      <p
                        class="courseTour"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        {{ allTours.course }}
                      </p>
                    </div>
                  </b-link>
                </b-col>
                <template v-if="loggedin === 'guest'">
                  <b-col lg="1" md="1">
                    <div class="posTop">
                      <div class="courseTour">
                        Rounds
                        <span
                          class="hideDesk mobvalue"
                          :class="{ major: allTours.major == 'Y' }"
                          >{{ allTours.rounds }}</span
                        >
                      </div>
                      <div class="courseTour">
                        Prize Fund
                        <span
                          class="hideDesk mobvalue"
                          v-if="allTours.total_prize_fund.length > 0"
                          :class="{ major: allTours.major == 'Y' }"
                        >
                          {{ allTours.total_prize_fund }}</span
                        >
                        <span
                          class="hideDesk mobvalue"
                          v-else
                          :class="{ major: allTours.major == 'Y' }"
                        >
                          N/A</span
                        >
                      </div>
                    </div>
                  </b-col>
                  <b-col lg="2" md="2" class="mobHide">
                    <div class="posTop">
                      <p
                        class="tourTitleMoreindi"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        {{ allTours.rounds }}
                      </p>
                      <p
                        class="tourTitleMoreindi"
                        v-if="allTours.total_prize_fund.length > 0"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        {{ allTours.total_prize_fund }}
                      </p>
                      <p
                        v-else
                        class="tourTitleMoreindi"
                        :class="{ major: allTours.major == 'Y' }"
                      >
                        N/A
                      </p>
                    </div>
                  </b-col>
                </template>
                <template v-else>
                  <b-col lg="1" md="1">
                    <div class="posTop">
                      <div class="courseTour">
                        Entries Close
                      </div>
                      <p class="tourTitleClosed">
                        <span v-if="allTours.entry_closed === 'Y'">
                          Entries Closed
                        </span>
                        <span
                          v-else
                          class="open"
                          :class="{ major: allTours.major == 'Y' }"
                        >
                          {{ allTours.closing_date }}-{{
                            allTours.closing_time
                          }}
                        </span>
                      </p>
                    </div>
                  </b-col>
                  <b-col lg="2" md="2">
                    <div class="posTop">
                      <div class="courseTour">
                        Entry Status
                      </div>
                      <p
                        class="tourTitleClosed"
                        v-if="allTours.entry_status === 'X'"
                      >
                        Not Entered
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'E'"
                        class="tourTitleEntered"
                      >
                        Entered
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'W'"
                        class="tourTitleClosed"
                      >
                        Withdrawn
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'R'"
                        class="tourTitleReserve"
                      >
                        Reserve
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'C'"
                        class="tourTitleCut"
                      >
                        Cut
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'L'"
                        class="tourTitleEntered"
                      >
                        Late Entry
                      </p>
                      <p
                        v-else-if="allTours.entry_status === 'Q'"
                        class="tourTitleQualify"
                      >
                        Required to Qualify
                      </p>
                    </div>
                  </b-col>
                </template>
                <b-col lg="2" md="2" class="mobHide">
                  <b-img
                    v-if="allTours.sponsor_logo.length"
                    class="tourSponLogo"
                    :src="allTours.sponsor_logo"
                  ></b-img>
                </b-col>
                <b-col lg="2" md="2" class="mobHide">
                  <div class="posTop">
                    <b-link
                      :to="{
                        name: 'tournament',
                        query: {
                          id: id,
                          code: allTours.code
                        }
                      }"
                      class="tourInfoBtn"
                    >
                      <span class="tournInfo">
                        Tournament Info
                        <span class="iconRight">
                          <font-awesome-icon
                            class="icon"
                            :icon="['fa', 'chevron-right']"
                          />
                        </span>
                      </span>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
            </template>
          </b-tab>
        </b-tabs>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "scheduleTable",
  props: ["id", "fields", "data", "loggedin", "course", "idName"],
  data() {
    return {
      box: "box",
      months: [
        {
          name: "January",
          month: "1",
          tourn: ""
        },
        {
          name: "February",
          month: "2",
          tourn: ""
        },
        {
          name: "March",
          month: "3",
          tourn: ""
        },
        {
          name: "April",
          month: "4",
          tourn: ""
        },
        {
          name: "May",
          month: "5",
          tourn: ""
        },
        {
          name: "June",
          month: "6",
          tourn: ""
        },
        {
          name: "July",
          month: "7",
          tourn: ""
        },
        {
          name: "August",
          month: "8",
          tourn: ""
        },
        {
          name: "September",
          month: "9",
          tourn: ""
        },
        {
          name: "October",
          month: "10",
          tourn: ""
        },
        {
          name: "November",
          month: "11",
          tourn: ""
        },
        {
          name: "December",
          month: "12",
          tourn: ""
        }
      ],
      future: true,
      past: true,
      test: ""
    };
  },
  methods: {
    dontknow: function() {
      var data = this.data;
      if (this.future == true) {
        console.log("True");
        data.forEach(value => {
          if (value.days_away > 0 && value.dates.includes("January")) {
            this.test = "DontShowMonth";
          }
        });
        return this.test;
      }
    }
  },
  computed: {
    filter() {
      return [
        ...new Map(
          this.data.map(item => [item.days_away.includes(this.month), item])
        ).values()
      ];
    }
  }
  // mounted() {
  //   this.dontknow();
  // }
};
</script>

<style scoped>
a.nameLink:hover > .posTop > .tourTitleMore {
  color: #f2af15;
  text-decoration: none!important;
}
a.nameLink:hover > .posTop > .courseTour {
  color: #f2af15;
  text-decoration: none!important;
}
span.iconRight {
  font-size: 12px;
  margin-left: 2px;
}
::v-deep a.nameLink:hover {
  color: #6c6c6c!important;
  text-decoration: none;
}
::v-deep .past {
  flex-direction: column-reverse;
  display: flex;
}
::v-deep .mt-3,
.my-3 {
  margin-top: 0rem !important;
}
.monthStyle {
  width: 100%;
  background-color: #006737;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  margin-bottom: 15px;
}
::v-deep .removeMonth43,
.removeMonth44,
.removeMonth45,
.removeMonth46,
.removeMonth48,
.removeMonth49,
.removeMonth50,
.removeMonth51 {
  display: none;
}
/* ::v-deep .ShowMonth {
  display: block;
}
::v-deep .DontShowMonth {
  display: none;
} */
::v-deep .tabClass > div > ul {
  border-bottom: 20px solid #046538;
}
::v-deep .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid #f2af15;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  color: #ffffff !important;
  background: #f2af15;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff !important;
  background-color: #026638;
  border-color: #026638 #026638 #026638;
}
::v-deep li.nav-item.nonactive > a {
  color: #f2af15 !important;
}
.major {
  color: #006737;
}
.index--0 {
  display: none;
}
.index--1 {
  display: none;
}
.index--2 {
  display: none;
}
.index--3 {
  display: none;
}
.tourRow:last-child {
  border-bottom: 0px solid;
}
.rowItem {
  margin-top: 20px;
  /* padding-left: 12px;
  padding-right: 12px; */
  padding-left: 0px;
  padding-right: 0px;
}
.outerMonth-BK {
  background-color: #006737;
  margin-top: -11px;
}
span.tournInfo {
  font-weight: bold;
  text-transform: capitalize;
}
a.btn.mobtournInfo.btn-secondary {
  background: none;
  border: none;
  color: #006737 !important;
  float: right;
}
span.mobvalue {
  color: #f2af14;
  font-weight: bold;
  padding-left: 12px;
}
::v-deep .monthBreakBack {
  background-color: #006737;
}
span.monthBreak {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  display: block;
  background-color: #006737;
  padding-left: 5px;
}
.current_tourn_row {
  /* color: #f2af19!important; */
  /* border: 3px solid #f2af15; */
  /* border-bottom: 3px solid #f2af15!important; */
  /* border-left: 2px solid #006737; */
  /* padding-left: 5px!important; */
}
.tourRow {
  /* margin-bottom: 19px;
  padding-bottom: 10px; */
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  /* box-shadow: 1px 1px 1px 1px #eaeaea; */
  border-bottom: 1px solid #d0d0d0;
}
.tourDates {
  color: #f2af15;
  font-weight: 700;
  padding-left: 0px;
}
.posTop {
  position: relative;
  top: 4px;
  font-size: 10pt;
  text-align: left;
  /* font: normal normal normal 10pt Barlow!important; */
}
.tourTitleMoreindi {
  color: #f2af14;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 6px;
  /* font: normal normal normal 11px Barlow!important; */
}
.tourTitleMore {
  color: #6c6c6c;
  font-weight: 700;
  font-size: 15px;
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tourTitleClosed {
  color: #d9534f;
  font-weight: 600;
  font-size: 10pt;
  width: auto;
}
.tourTitleEntered {
  color: #006837;
  font-weight: 600;
  font-size: 10pt;
  width: auto;
}
.tourTitleReserve {
  color: #680051;
  font-weight: 600;
  font-size: 10pt;
  width: auto;
}
.tourTitleCut {
  color: #f2af14;
  font-weight: 600;
  font-size: 10pt;
  width: auto;
}
.tourTitleQualify {
  color: blue;
  font-weight: 600;
  font-size: 10pt;
  width: auto;
}
.open {
  color: black;
}
.courseTour {
  color: #848484;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 6px;
  /* font: normal normal normal 11px Barlow!important; */
}
.tourInfoBtn {
  text-align: center;
  display: block;
  margin: auto;
  background: none;
  border: none;
  color: #6c6c6c !important;
  border-radius: 0;
  padding: 5px 20px;
  width: auto;
  font-size: 9pt;
  font-weight: bold;
  font-size: 13px;
  text-transform: capitalize;
}
.tourInfoBtn:hover {
  text-align: center;
  display: block;
  margin: auto;
  background: none;
  border: none;
  color: #f2af14 !important;
  border-radius: 0;
  padding: 5px 20px;
  width: auto;
  font-weight: bold;
  text-decoration: none;
}
.tourSponLogo {
  max-height: 40px;
  top: 6px;
  position: relative;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .hideDesk {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .tourDates {
    padding-left: 15px;
  }
  .mobHide {
    display: none;
  }
  /* .mobileTop {
    padding-top: 30px!important;
  }
  .groupDropdown {
    margin-top: 2em;
  } */
}
</style>
