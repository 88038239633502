<template>
  <div class="tour">

    <div class="sectionBackground" :style="{ backgroundImage: 'url(' + DEFIMAGESAMS3 + 'sst' + '/' + 'courses' + '/' +
      id +
      '/' +
      tourinfo.course_code +
      '/' +
      tourinfo.course_image_file_1 + ')' }"
    >

    </div>
    <b-container>
      <div v-for="(coSantion, index) in InfoData" :key="index">
        <div v-if="coSantion.acf.tour_code == $route.query.code">
          <div class="ticketBlock">
            <b-row>
              <b-col><p class="ticketsText">Tickets from *incl VAT</p></b-col>
              <b-col><span class="TicketPrice">{{coSantion.acf.ticket_price}}</span></b-col>
            </b-row>
            <a :href="coSantion.acf.ticket_link" target="_blank" class="buynowHover">
              <div class="BuyNow">Buy Now</div>
            </a>
          </div>
        </div>        
      </div>
    </b-container>
    <b-container style="padding-bottom:50px;">
      <b-row class="rowWidth">
        <b-col>
          <div class="tourInfo">
            <template v-if="tourinfo.sponsor_logo !== ''">
              <img class="sponsorLogo" :src="tourinfo.sponsor_logo">
            </template>
            <div class="courseInfo">
              <h2 class="tourCourse-name">{{tourinfo.course_name}}</h2>
              <h2 class="tourCourse-dates">{{tourinfo.tourn_dates}}</h2>
            </div>
          </div>
          <div class="tourInfo">
            <div v-for="(coSantion, index) in InfoData" :key="index">
              <div v-if="coSantion.acf.tour_code == $route.query.code">
                <p v-html="coSantion.acf.tournament_bio"></p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div v-for="(coSantion, index) in InfoData" :key="index">
            <div v-if="coSantion.acf.tour_code == $route.query.code">
              <template v-if="coSantion.acf.winner_found == true">
                <img class="WinnerImage" :src="coSantion.acf.winner_image">
                <h2 class="Winner-Defender"><span class="textChampion">Winner - </span>{{ coSantion.acf.winner_name }}</h2>
              </template>
              <template v-if="coSantion.acf.defending_champion_found == true">
                <img class="WinnerImage" :src="coSantion.acf.defending_champion">
                <h2 class="Winner-Defender"><span class="textChampion">Defending Champion -</span> {{ coSantion.acf.defending_champion_name }}</h2>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-for="(coSantion, index) in InfoData" :key="index">
      <div v-if="coSantion.acf.tour_code == $route.query.code">
        <div v-for="(coSantionText, index) in coSantion.acf.text_section" :key="index" class="coSactionBlock">
          <b-container class="freeTextSection">
            <h1 class="infoTitle">{{coSantionText.title}}</h1>
            <template v-if="coSantionText.image.length > 0">
              <b-row>
                <b-col sm="12" lg="8">
                  <p class="inforDescription" v-html="coSantionText.description"></p>
                </b-col>
                <b-col sm="12" lg="4">
                  <div class="outerImageCoSantion">
                    <div class="imageCoSantion" :style="{'background-image': 'url(' + coSantionText.image + ')'}">
                    </div>                  
                  </div>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-row>
                <b-col cols="12">
                  <p class="inforDescription" v-html="coSantionText.description"></p>
                </b-col>
              </b-row>
            </template>
            <template v-if="coSantionText.video.length > 0">
              <div class="videoContent" v-html="coSantionText.video">
              </div>
            </template>
          </b-container>
        </div>
      </div>        
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "Ticket Open",
  props: ["config", "loggedin", "course", "title"],
  data() {
    return {
      tourinfo: [],
      InfoData: [],
      splash: "splash",
      splashText: "splashText",
      logo: "logo",
      overlay: "overlay",
      courseStats: "courseStats",
      until: [],
      id: this.$route.query.id,
      code: this.$route.query.code,
      // title: "",
      form: {
        text: ""
      },
      polling: null
    };
  },
  metaInfo() {
    return {
      title: this.tourinfo.full_name,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  methods: {
    isMobile() {
        if( screen.width <= 760 ) {
            return true;
        }
        else {
            return false;
        }
    },
    async login() {
      try {
        // First call, await for call to return
        let first_call = await apiCall.tmParamsPlayer(
          this.id,
          this.loggedin.playerID,
          this.code
        );
        this.tourinfo = first_call.data;
        this.until = parseInt(first_call.data.days_away);
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
    async notLogin() {
      try {
        // First call, await for call to return
        let first_call = await apiCall.tmParams(this.id, this.code);
        this.DEFIMAGESAMS3 = first_call.data.tm_params.DEFIMAGESAMS3;
        this.tourinfo = first_call.data;
        this.until = first_call.data.days_away;
        this.loading = false;
        // this.refreshData();
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
    pollData() {
      this.polling = setInterval(() => {
        // console.log("Run refresh");
        this.notLogin();
      }, 30000);
    }
  },
  async mounted() {
    // this.title = 'Round 1 Scores Only';
    var two = "wp/v2/cosanctionedevents" + "?randomadd=" + new Date().getTime()
    try {
      // Second call, await for call to return
        let Info = await apiCall.wpInfo(two);
        this.InfoData = Info.data;
        this.loading = false;
      // Second call, await for call to return
    } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
    }
    if (this.loggedin.status === null || this.loggedin === "") {
      this.notLogin();
      this.pollData();
    } else {
      this.login();
    }
  }
};
</script>
<style scoped>
.videoContent {
  margin-top: 50px;
}
::v-deep iframe {
  width: 100%!important;
}
.outerImageCoSantion {
  background-color: #fff;
  box-shadow: 5px 5px 5px 5px #f1eeee;
  padding: 10px;
}
.imageCoSantion {
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.santionImage {
  width: 100%;
}
.coSactionBlock:nth-child(odd) {
  background-color: #F2F2F2;
}
.coSactionBlock {
  padding-bottom: 30px;
  padding-top: 30px;
}
span.textChampion {
  font-weight: 400;
  color: #6C6C6C;
}
h2.Winner-Defender {
  font-size: 20px;
  color: #F2AF14;
  font-weight: bold;
  margin-top: 30px;
  text-align: center;
}
.freeTextSection {
  padding: 50px;
}
.rowWidth {
  width: 100%;
  margin-left: 0px;
}
a.buynowHover:hover > div {
  background-color: #006737;
  text-decoration: none!important;
}
.ticketBlock {
  width: 220px;
  background-color: #fff;
  padding: 20px;
  /* position: absolute; */
  /* right: 14em; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-bottom: 28px;
  padding-top: 25px;
  margin-top: -9em;
  float: right;
}
.ticketsText {
  font-size: 11px;
  color: #6C6C6C;
  margin-top: 12px;
}
.TicketPrice {
  font-size: 36px;
  font-weight: bold;
  color: #6C6C6C;
}
.BuyNow {
  color: #fff;
  background-color: #F2AF14;
  text-align: center;
  padding: 5px;
}
.courseInfo {
  margin-top: 40px;
}
.tourCourse-name {
  font-size: 20px;
  font-weight: 400;
}
.tourCourse-dates {
  font-size: 20px;
  color: #F2AF14;
  font-weight: bold;
}
h1.infoTitle {
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
}
p.inforDescription {
  color: #6C6C6C;
  font-size: 13px;
}
.grey-backgroundSection {
  background-color: #F2F2F2;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.sponsorLogo {
  width: auto;
  margin-top: 2em;
}
.WinnerImage {
  width: 100%;
  margin-top: 100px;
  background: white;
  padding: 10px;
  box-shadow: #000;
  box-shadow: 5px 5px 5px 5px #f1eeee;
}
.courseimage-hide.courseImg.col-lg-4 {
  background-repeat: no-repeat;
  background-size: cover;
}
.heightColorDiv {
  background: #006737;
  height: 30px;
  display: none;
}
span.winnerTag {
  font-weight: 600!important;
  padding-right: 5px;
}
.winnerProfile-Image.col-lg-4 {
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center;
}
.sectionBackground {
  background: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
  background-repeat: no-repeat;
  padding-bottom: 7em;
  padding-top: 5em;
  background-size: cover;
  height: 450px;
}
.over {
  overflow: hidden;
}
::v-deep img.courseImg {
  height: 340px;
  background-size: cover;
}
::v-deep .mt-3, .my-3 {
  margin-top: 0rem!important;
}
.reportSection {
  padding: 0px!important;
}
::v-deep .dropdown-item {
  text-transform: capitalize;;
  font-size: 14px;
  padding: 0rem 1.5rem;
  border-radius: 0px;
}
::v-deep .dropdown-menu.show {
  display: block;
  width: 100%;
  text-align: center;
}
.courseinfo {
  width: 100%;
}
.row.row-cols-yellowouterblock {
  height: 20px;
  background-color: #F2AF14;
  border-radius: 10px 10px 0px 0px;
  margin-left: -2px;
  margin-right: -2px;
}
.memberTournDocs > button {
  border-radius: 5px;
}
ul.nav.dropdownSched {
  float: right;
  margin-top: 8px;
}
.toptournInfo {
  margin-left: -2px;
  margin-right: -2px;
}
span.multiText {
  color: #016837;
  font-size: 15pt;
  font-weight: 700;
}
span.multiDot-size {
  position: relative;
  font-size: 65px !important;
  line-height: 0px !important;
  vertical-align: middle;
  top: -4px;
}
.multiCourseIndex {
  text-align: center;
  margin-top: 3em;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1e90ff;
}
.R {
  color: #bb0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}

.B > th {
  background-color: #333333 !important;
}
.Y > th {
  background-color: #d8db22 !important;
}
.C > th {
  background-color: #1e90ff !important;
}
.R > th {
  background-color: #bb0000 !important;
}
.G > th {
  background-color: #3c9933 !important;
}
.O > th {
  background-color: #e07020 !important;
}
.lastRealTime-update {
  margin-top: 30px;
  margin-bottom: 2em;
}
::v-deep .tabsLi > div > ul > li {
  margin-right: 20px;
}
::v-deep .nav-tabs {
  border-bottom: 0;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f2af15;
  /* border-color: #dee2e6 #dee2e6 #fff; */
  color: #fff !important;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0px;
}
::v-deep .nav-tabs .nav-link {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #026034;
  border-radius: 0px;
  color: #fff !important;
  border: 0;
}
.TitleArea {
  margin-bottom: 0px;
  margin-top: 5px;
}
.TitleAreaScore {
  margin-bottom: 25px;
  margin-top: 40px;
}
h3.courseDates {
  color: #026034;
  font-weight: bold;
  font-size: 14px;
  text-align: left !important;
}
::v-deep .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 10px;
}
.splash {
  width: 100%;
}
.topPad {
  padding-top: 2rem;
}
.backBtn {
  background: rgb(242, 175, 20);
  padding: 10px;
  color: #fff !important;
  border: 0;
  font-size: 12pt;
  margin-bottom: 5em;
  border-radius: 0px;
}
.backBtn:hover {
  background: #006737;
  padding: 10px;
  color: #fff !important;
  border: 0;
  font-size: 12pt;
  margin-bottom: 5em;
  border-radius: 0px;
}
.reportInfo {
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
}
.tourInfo {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  color: #6C6C6C;
  font-size: 13px;
}
.reportInfo {
  padding: 25px;
}
.dateTitle {
  /* padding-left: 35px; */
}
.reportDate {
  color: #f2af15;
  font-size: 13px;
  margin-bottom: 30px;
  margin-top: 25px;
  text-transform: capitalize;;
}

.reportTitle {
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
   
}
.courseName {
  color: #939393;
  font-size: 9pt;
  font-weight: 100;
  margin-top: 25px;
  display: block;
  font-weight: bold;
}
.logoOverflow {
  /* overflow: hidden;
  height: 210px; */
}
.sponLogo {
  max-height: 120px;
  width: auto;
  margin-top: -30px;
  float: right;
}
.secondInfo {
  margin-top: 30px;
}
.listTourInfo {
  color: #939393;
  margin-bottom: 0;
  text-align: left;
  font-size: 9pt;
}
.valueReport {
  color: #F2AF14;
  font-weight: 700;
  margin-bottom: 0;
  text-align: left;
}
.reportTourBtn {
  display: block;
  /* margin: auto; */
  background: #006737;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 11px;
  margin-top: 30px;
}
.reportTourBtn:hover {
  display: block;
  /* margin: auto; */
  background: #F2AF14;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 11px;
  margin-top: 30px;
}
.memberButton {
  display: block;
  margin: auto;
  border: none;
  color: #fff !important;
  border-radius: 0px;
  padding: 5px 20px 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
}
.backgroundTourImg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0px 0px 0px 10px;
}
.secondList {
  margin-bottom: 0;
  text-align: left;
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
 
}
.secondLead {
  font-size: 1.25rem;
  font-weight: 300;
  text-align: left;
  color: #f2af15;
  margin-bottom: 0;
}
.tourInfoHeading {
  margin-top: 0px;
  padding-bottom: 0.3em;
  line-height: 1.25;
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
 
}
tr > th {
  padding: 0.55rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
  background: #214232;
  color: #fff;
  /* font-size: 1rem; */
  font-size: 13px;
}
tr > td {
  padding: 0.55rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
  background-color: rgba(0, 0, 0, 0.05);
  /* font-size: 1rem; */
  font-size: 13px;
}
.reportSele {
  padding: 20px;
  margin-top: 2em;
}
.selectReport {
  text-align: center;
  text-transform: capitalize;
  margin-top: 60px;
  line-height: 25px;
  margin-bottom: 25px;
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
}
::v-deep .dropdown > button {
  text-align: left;
  display: block;
  margin: auto;
  border-radius: 0;
  width: 475px;
  background-color: white;
  color: #495057;
  border: 1px solid #E0E0E0;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 13px;
}
.greenProfile {
  background-color: #006737;
}
::v-deep .modal-content {
  background-color: #f0f0f0;
}
::v-deep .modal-title {
  color: #026034;
}
::v-deep .modal-header {
  border-bottom: 0;
}
@media only screen and (min-width: 991px) {
  .courseImageHidDesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  img.courseImg {
    height: 241px;
    background-size: cover;
  }
  .reportInfo {
    background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
  }
  .courseImageHid {
    display: none;
  }
  .backgroundTourImg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0px 0px 0px 0px;
    padding-left: 0px;
    overflow: hidden;
    height: 100%;
  }
  img.courseCodeImage {
    /* width: 100%; */
    height: 100px;
    min-height: 352px;
    border-radius: 0px 0px 0px 0px;
    min-width: 1050px;
  }
}
@media only screen and (max-width: 768px) {
  ul.nav.dropdownSched {
    float: none;
    margin-top: 8px;
  }
  ul.nav.dropdownSched > div {
    width: 100%;
  }
  .sponLogo {
    height: auto;
    width: 100%;
    margin-top: 30px;
    float: right;
  }
}

@media only screen and (max-width: 500px) {
  .dateTitle {
    padding-left: 0px;
  }
  ::v-deep .dropdown > button {
    text-align: left;
    display: block;
    margin: auto;
    border-radius: 0;
    width: 300px;
    background-color: white;
    color: #495057;
    border: 1px solid #E0E0E0;
    text-transform: capitalize;
    font-weight: 400;
  }
  ::v-deep .dropdown-toggle::after {
    display: inline-block;
    margin-left: 1.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
    margin-top: 10px;
  }
}
</style>