<template>
  <div id="newsStory">
    <b-container class="content">
      <b-row v-if="this.$route.name === 'news story'">
        <b-col cols="12" lg="8">
          <b-row>
            <template v-if="postInfo[0].categories[0] === 10">
              <b-col class="showDesk">
                <b-nav-item to="/the-score">
                  <h3 class='back'>
                    Back To The Score Stories
                  </h3>
                </b-nav-item>
              </b-col>
              <b-col class="showMob" cols="3">
                <b-nav-item to="/the-score">
                  <h3 class='mobBack'>
                    Back
                  </h3>
                </b-nav-item>
              </b-col>
            </template>

            <template v-else>
              <b-col class="showDesk">
                <b-nav-item to="/news">
                  <h3 class='back'>
                    Back To News Stories
                  </h3>
                </b-nav-item>
              </b-col>
              <b-col class="showMob" cols="3">
                <b-nav-item to="/news">
                  <h3 class='mobBack'>
                    Back
                  </h3>
                </b-nav-item>
              </b-col>
            </template>
            <b-col>
              <span class="networkShare">
                <h2 class="shareWith">Share with:</h2>
                <ShareNetwork
                    network="facebook"
                    :url="'https://sunshinetour.com/' + postInfo[0].slug"
                    :img-src="postInfo[0].better_featured_image.source_url"
                    img-alt="Image"
                    img-top
                    :title="postInfo[0].title.rendered"
                    :quote="postInfo[0].title.rendered"
                  >
                    <img class="shareImage" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Facebook_Green@2x-1.png">
                </ShareNetwork>
                <ShareNetwork
                    network="Twitter"
                    :url="'https://sunshinetour.com/' + postInfo[0].slug"
                    :img-src="postInfo[0].better_featured_image.source_url"
                    img-alt="Image"
                    img-top
                    :title="postInfo[0].title.rendered"
                    :quote="postInfo[0].title.rendered"
                  >
                    <img class="shareImage" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2023/11/Twitter_Green.png">
                </ShareNetwork>
                <ShareNetwork
                    network="InstaPaper"
                    :url="'https://sunshinetour.com/' + postInfo[0].slug"
                    :img-src="postInfo[0].better_featured_image.source_url"
                    img-alt="Image"
                    img-top
                    :title="postInfo[0].title.rendered"
                    :quote="postInfo[0].title.rendered"
                  >
                    <img class="shareImage" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Instagram-_Green@2x-2.png">
                </ShareNetwork>
                <ShareNetwork
                    network="WhatsApp"
                    :url="'https://sunshinetour.com/' + postInfo[0].slug"
                    :img-src="postInfo[0].better_featured_image.source_url"
                    img-alt="Image"
                    img-top
                    :title="postInfo[0].title.rendered"
                    :quote="postInfo[0].title.rendered"
                  >
                    <img class="shareImage" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/WhatsApp_Green-1.png">
                </ShareNetwork>
              </span>
            </b-col>
          </b-row>
          <b-img
            class="storyImg"
            :src="postInfo[0].better_featured_image.source_url"
            fluid 
            alt="Responsive image"
          />
          <h3 class="dateTime">Published {{dateTime}}</h3>
          <h2 v-html="postInfo[0].title.rendered" class="article-heading"></h2>
          <span v-html="postInfo[0].content.rendered" class="newsDescript"></span>
        </b-col>
        <b-col cols="12" lg="4" class="hideMob">
          <b-row class='topPad'>
            <!-- <div>
              <a href="https://www.cubefunder.com/" target="_blank">
                <b-img class="adimage" :src="'https://updated.sunshinetour.com/wp-content/uploads/2022/02/ad.png'" />
              </a>
            </div> -->

            <b-col
              class="col-md-12"
              v-for="(recent, index) in recent.slice(0, 5)"
              :key="index"
            >
              <b-nav-item :to="'/' + recent.slug" class="box">
                <b-card
                  title="Recent"
                  :sub-title="recent.title.rendered"
                  :img-src="recent.better_featured_image.source_url"
                  img-alt="Image"
                  img-top
                  tag="article"
                >
                </b-card>
                <div class="viewArticle">
                  <b-link :to="'/' + recent.slug">
                    View Article
                  </b-link>
                </div>
              </b-nav-item>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="this.$route.name === 'thescorestory'">
        <b-col>
          <b-img
            class="storyImg"
            :src="postInfo.better_featured_image.source_url"
            fluid
            alt="Responsive image"
          />
          <h3>Published {{dateTime}}</h3>
          <h2 v-html="postInfo.title.rendered" class="article-heading"></h2>
          <span v-html="postInfo.content.rendered" class="newsDescript"></span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "postFull",
  props: ["id", "config"],
  data() {
    return {
      postInfo: [],
      recent: [],
      related: [],
    };
  },
  computed: {
    dateTime:function(){
      var dateOG = this.postInfo[0].date.split('T')
      var splitDate = dateOG[0].split('-')
      var newDate = splitDate[2]+ '/' + splitDate[1] + '/' + splitDate[0]
      var time = dateOG[1]
      return newDate + ' - ' + time

    }
  },
  async mounted() {
      var first = "wp/v2/posts?slug=" + this.id
      var two = "wp/v2/posts/"
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(first);
      this.postInfo = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo(two);
      this.recent = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
  metaInfo() {
    return {
      // title: this.postInfo[0].title.rendered,
      title: 'Article',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },

};
</script>

<style scoped>
h3.dateTime {
  font-size: 13px;
  color: #6C6C6C;
}
.viewArticle > a {
  color: #fff!important;
}
.viewArticle:hover {
  background-color: #f2af15;
  text-decoration: none;
}
.viewArticle > a:hover {
  text-decoration: none;
}
.viewArticle {
  background-color: #006737;
  text-transform: capitalize;
  text-align: center;
  font-size: 11px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  line-height: 14px!important;
  /* font: normal normal normal 11px Barlow; */
  height: 35px;
}
span.networkShare {
  display: inline-flex;
  float: right;
  margin-top: 50px;
}
.shareImage {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
::v-deep div#div-gpt-ad-1645437162352-0 {
  width: 358px;
  height: 250px;
}
::v-deep div#google_ads_iframe_\/21903684810\/webadtwo-500x400_0__container__ {
  width: 358px;
  height: 250px;
}
::v-deep div#google_image_div {
  width: 358px;
  height: 250px;
}
::v-deep img.adimage {
  width: 358px!important;
  height: 250px!important;
  margin-left: 15px;
}
h2.shareWith {
  font-size: 13px;
  color: #D0D0D0;
  text-transform: capitalize;
  margin-top: 7px;
  padding-right: 20px;
}
.article-heading {
  font-size: 21px;
  color: #6C6C6C;
  font-weight: bold;
  margin-bottom: 30px;
}
.content {
  padding: 50px 0;
}
.newsDescript {
  font-size: 13px;
  color: #6C6C6C;
}
.storyImg {
  margin-top: -11px;
}
.card-img, .card-img-top {
  border-radius: 0px!important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  padding-left: 0;
  width: 100%;
}
.card-title {
  font-weight: 100;
  letter-spacing: -0.5px;
  color: #f2af15;
  margin-top: -2px;
  margin-bottom: -5px;
  font-size: 11px;
}
.card-subtitle {
  color: #6C6C6C !important;
  font-weight: bold;
  font-size: 13px;
  margin-top: 15px;
}
.nav-link {
  padding: 0;
}
.title {
  font-weight: 500;
  font-family: "gotham book", "roboto", sans-serif !important;
  color: #000;
  font-size: 18px;
  margin-top: 1em;
  line-height: 1.1;
  letter-spacing: 2px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: #24292e;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 15px;
}
.mobBack {
  color: #ffffff;
  font-size: 12pt;
  margin-bottom: 5em;
  font-weight: 500;
  background: #006737;
  width: 100px;
  padding: 10px;
  text-align: center;
  font-size: 11px;
}
.mobBack:hover {
  color: #ffffff;
  font-size: 12pt;
  margin-bottom: 5em;
  font-weight: 500;
  background: #204232;
  width: 100px;
  padding: 10px;
  text-align: center;
  font-size: 11px;
}
.back{ 
  color: #ffffff;
  font-size: 11px;
  margin-bottom: 5em;
  font-weight: bold;
  background: #006737;
  width: 200px;
  padding: 10px;
  text-align: center;
  font-size: 11px;
  line-height: 14px!important;
  /* font: normal normal normal 11px Barlow; */
  height: 35px;
}
.back:hover{
  color: #ffffff;
  font-size: 11px;
  margin-bottom: 5em;
  font-weight: bold;
  background: #204232;
  width: 200px;
  padding: 10px;
  text-align: center;
  line-height: 14px!important;
  /* font: normal normal normal 11px Barlow; */
  height: 35px;
}
.topPad{
  padding-top: 100px;
}

@media only screen and (max-width: 990px) {
  .hideMob {
    display: none;
  }
}
@media only screen and (min-width: 501px) {
  .showMob {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .showDesk {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  div#newsStory {
    padding: 20px;
  }
}
</style>
